import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Input, Output, EventEmitter, Inject, PLATFORM_ID, ChangeDetectorRef, NgZone, computed, inject, Signal, signal, Injector, effect, ElementRef, HostListener, DestroyRef, runInInjectionContext } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FormatTimePipe } from '@pipes/format-time.pipe';
import {  Subscription, Subject, BehaviorSubject, firstValueFrom } from 'rxjs';

import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AudioDetectionService } from '@services/audio-detection.service';
import { openDB } from 'idb';
import { ModeOverlayComponent } from '@components/overlays/mode-overlay/mode-overlay.component';
import { Firestore, doc, getDoc, collection, addDoc, writeBatch, getDocs, updateDoc, Timestamp } from '@angular/fire/firestore';
import { UserService } from '@services/user.service';

import { RecordingService } from '@services/recording.service';
import { ToastService } from '@services/toast.service';
import { DebugLoggerService } from '@services/debug-logger.service';
import { ResizeService } from '@services/resize.service';
import { CanvasDrawService } from '@services/canvas-draw.service';
import { RecordingTimerService } from '@services/recording-timer.service';
import { SoundProfileService } from '@services/sound-profile.service';
import { WebcamControlService } from '@services/webcam-control.service';
import { debounce } from 'lodash';

const DEBUG_MODE = true; // Set this to false in production

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css'],
  standalone: true,
  imports: [CommonModule, SharedModule, FormatTimePipe, ModeOverlayComponent]
})
export class WebcamComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('webcamContainer') webcamContainer!: ElementRef<HTMLDivElement>;
  private recordingCanvas: HTMLCanvasElement | null = null;
  private debouncedCheckViewportSize: () => void;
  private isCountdownActive = false;



  @Input() detectionMode = false;
  @Input() demoMode = false;
  @Output() demoModeChange = new EventEmitter<boolean>();
  @Output() saveVideo = new EventEmitter<{ downloadURL: string; filename: string; }>();
  @Output() sendVideo = new EventEmitter<void>();
  @Output() videoRecorded = new EventEmitter<{ blob: Blob, duration: number }>();
  @Output() recordingFinished = new EventEmitter<Blob | string>();
  @Output() modeSelected = new EventEmitter<'Pinwheel' | 'Bubbles' | 'Music'>();
  @Output() recordingStarted = new EventEmitter<void>();
  @Output() videoSaved = new EventEmitter<Blob>();
  @Output() videoDeleted = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<string | Event>();
  @Output() webcamActiveChange = new EventEmitter<boolean>();
  @Output() uploadComplete = new EventEmitter<{ filename: string, url: string }>();
  @Output() recordingSaved = new EventEmitter<{ filename: string, url: string }>();
  @Output() recordingDeleted = new EventEmitter<void>();
  @Output() discardRecording = new EventEmitter<void>();

  @ViewChild('motivatorDropdown') motivatorDropdown!: ElementRef<HTMLDetailsElement>;
  @ViewChild('confirmModal') confirmModal!: ElementRef;
  @ViewChild('successModal') successModal!: ElementRef;
  @ViewChild('stopModal') stopModal!: ElementRef<HTMLDialogElement>;

  // UI-related properties
  webcamImage: any;
  randomPosterUrl!: string;
  recordingTime = '00:00';
  videoBlob: Blob | null = null;
  availableEffects: Array<'Pinwheel' | 'Bubbles' | 'Music'> = [];
  selectedMode: 'Pinwheel' | 'Bubbles' | 'Music' | null = 'Pinwheel';
  motivatorColorClass = 'bg-blue-300 text-blue-900';
  selectedMotivator = '🎡 Pinwheel';
  isPreparingForEditing = false;
  preparingProgress = 0;

  // Calibration-related properties
  calibrationStep: 'blow' | 'laugh' | 'clap' | 'hello' | null = null;
  calibrationInstructions = '';
  calibrationCountdown = 0;
  calibrationData: number[] = [];

  // Private properties
  private stream: MediaStream | null = null;
  private routerSubscription!: Subscription;
  private recordingStartTime = 0;
  private pausedTime = 0;
  private audioSubscription: Subscription | null = null;
  private recordingInterval: any;
  private onConfirm: (() => void) | null = null;
  private blowingCardTimer: any;
  private canvasStream: MediaStream | null = null;
  private animationFrameId: number | null = null;
  private lastTimestamp = 0;
  private destroy$ = new Subject<void>();
  private isLoggingTimer = true;
  private hasLoggedTimerDrawing = false;
  private countdownStarted = false;

  // State flags
  isRecording = false;
  webcamStarted = false;
  isPaused = false;
  showSuccessCard = false;
  demoStep = 1;
  demoStepCompleted = false;
  demoLaunched = false;
  moveUp = false;
  showBlowingCard = false;
  showSaveDialog = false;
  isAnimating = false;
  showSaveOptions = false;
  isUploading = false;
  cameraVisible = true;
  showTriggerAbsenceCard = false;
  showKeepBlowingCard = false;

  // Audio and recording related properties
  consecutivePositivePatterns = 0;
  remainingTime = 180;
  currentAudioLevel: Signal<number>;
  maxObservedLevel = 0.6;
  recordingDuration = 0;
  recordingProgress = 0;
  lastFrameAspectRatio = 16 / 9;

  // Signals and Observables
  webcamActive = signal(false);
  uploadSuccess = signal(false);
  audioLevel$ = new BehaviorSubject<number>(0);
  isRecordingStopped = signal(false);
  uploadProgress = signal(0);
  isCalibrating = signal(false);
  previousLevels = signal<number[]>([]);

  // Miscellaneous properties
  confirmMessage = '';
  lastFrame: string | null = null;
  recordedBlob: Blob | null = null;

  private userService = inject(UserService);
  isAdmin = this.userService.isAdmin;

  // Getters
  getStream(): MediaStream | null {
    return this.stream;
  }

  get audioLevel(): Signal<number> {
    return this.audioDetectionService.audioLevel;
  }

  get threshold() {
    return this.audioDetectionService.threshold;
  }

  get acceleration() {
    return this.audioDetectionService.acceleration;
  }

  get breathDetected() {
    return this.audioDetectionService.breathDetected;
  }

  get positivePatternDetected() {
    return this.audioDetectionService.positivePatternDetected;
  }

  // Fullscreen video state
  isFullscreenVideo = new BehaviorSubject<boolean>(false);

  @Input() debugMode = false;

  private isClosing = false;
  private resizeObserver: ResizeObserver | null = null;
  private drawDimensions = { drawWidth: 0, drawHeight: 0, xOffset: 0, yOffset: 0 };
  private _isLargeViewport = false;

  private resizeListener: () => void;
  

  private audioDetectionService = inject(AudioDetectionService);
  private soundProfileService = inject(SoundProfileService);
  private webcamControlService = inject(WebcamControlService);
  private recordingService = inject(RecordingService);
  private recordingTimerService = inject(RecordingTimerService);
  private resizeService = inject(ResizeService);
  private canvasDrawService = inject(CanvasDrawService);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private firestore: Firestore,
    private injector: Injector,
    private elementRef: ElementRef,
    
    private toastService: ToastService,
    private route: ActivatedRoute,
    private debugLogger: DebugLoggerService,
  ) {
    this.log('AudioDetectionService injected:', this.audioDetectionService);

    this.currentAudioLevel = this.audioDetectionService.audioLevel;

    this.resizeListener = this.onWindowResize.bind(this);

    // Initialize viewport size
    this.checkViewportSize();

    
    // Debounce the checkViewportSize method
    this.debouncedCheckViewportSize = debounce(this.checkViewportSize.bind(this), 250);
  }

  get isLargeViewport(): boolean {
    return this._isLargeViewport;
  }


  private checkViewportSize(): void {
    this._isLargeViewport = window.innerWidth >= 1024; // Adjust this threshold as needed
    if (this.webcamStarted) {
      this.adjustWebcamContainerSize();
      this.adjustCanvasSize();
    }
  }
  

  ngOnInit() {
    this.demoMode = !this.detectionMode;
        this.checkViewportSize();

    this.randomPosterUrl = this.getRandomPoster();

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.webcamStarted) {
        this.stopWebcam();
      }
    });

    this.loadAvailableEffects();
    this.loadSoundProfiles();

    
    this.recordingService.isRecording$.subscribe(isRecording => {
      this.isRecording = isRecording;
    });

    this.recordingService.recordingProgress$.subscribe(progress => {
      this.recordingProgress = progress;
    });

    this.uploadProgress = this.recordingService.getUploadProgress();

    // Subscribe to the recordedBlob$ observable
    this.recordingService.recordedBlob$.subscribe(blob => {
      this.recordedBlob = blob;
    });

    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
    });

    // Add event listener for resize
    window.addEventListener('resize', this.debouncedCheckViewportSize);

    this.resizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.resizeListener);

    this.resizeService.logDimensionsWebcamOff(
      this.platformId,
      this.webcamContainer?.nativeElement,
      this.isLargeViewport,
      this.detectionMode
    );

    // Reset recording state when component initializes
    this.recordingService.resetRecording();

    this.recordingTimerService.remainingTime$.subscribe(time => {
      console.log('WebcamComponent: Received updated time:', time);
      this.remainingTime = time;
      // Trigger a redraw of the canvas here if needed
    });
  }

  ngOnDestroy(): void {
    // Stop webcam and reset related states
    this.stopWebcam();
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.demoLaunched = false;
    this.demoStep = 1;
    this.demoStepCompleted = false;
    this.showBlowingCard = false;
    this.moveUp = false;
    this.resetRecordingState();
    this.remainingTime = 180;
    this.stopAudioDetection();
    this.isAnimating = false;

    // Clean up timers and animations
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.cancelAnimationFrame();

    // Reset UI states
    this.resetConsecutivePatterns();
    this.showKeepBlowingCard = false;
    this.isFullscreenVideo.next(false);

    // Clean up DOM elements
    if (this.webcamContainer?.nativeElement) {
      this.webcamContainer.nativeElement.style.height = '';
    }
    if (this.videoElement?.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
      this.videoElement.nativeElement.classList.add('blur-md');
    }

    // Clean up subscriptions and observers
    this.destroy$.next();
    this.destroy$.complete();
    this.routerSubscription?.unsubscribe();
    this.audioSubscription?.unsubscribe();

    // Stop media streams
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }

    // Clean up services
    this.audioDetectionService.stopAudio();
    this.audioDetectionService.setWebcamStarted(false);

    // Remove event listeners and observers
    this.destroyResizeObserver();
    window.removeEventListener('resize', this.debouncedCheckViewportSize);
    window.removeEventListener('resize', this.resizeListener);

    // Final UI updates
    this.adjustWebcamSize();
    this.changeDetectorRef.detectChanges();

    // Reset recording state when component is destroyed
    this.recordingService.resetRecording();
    this.stopDrawLoop();
  }


    ngAfterViewInit(): void {
      // Ensure the container is initialized before logging
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.adjustWebcamContainerSize();
          this.resizeService.logDimensionsWebcamOff(
            this.platformId,
            this.webcamContainer?.nativeElement,
            this.isLargeViewport,
            this.detectionMode
          );
        }, 0);
      });

      // Start the draw loop
      this.startDrawLoop();

      // Initialize canvas animation
      this.animateCanvas();

      // Other initialization code can go here
    }

  private drawToCanvas(): void {
    if (!this.canvasElement || !this.videoElement) return;

    const canvas = this.canvasElement.nativeElement;
    const video = this.videoElement.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the video frame if camera is visible and video is ready
    if (this.cameraVisible && video.readyState === video.HAVE_ENOUGH_DATA) {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    } else {
      // Draw black background when camera is off
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

  }

  // Make sure this method is called in the animation loop
  private animateCanvas(): void {
    this.drawToCanvas();
    this.animationFrameId = requestAnimationFrame(() => this.animateCanvas());
  }
 

  private startDrawLoop(): void {
    const animate = () => {
      this.drawToCanvas();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    animate();
  }

  private stopDrawLoop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }
  

  private initializeCanvas() {
    if (this.canvasElement && this.videoElement) {
      const canvas = this.canvasElement.nativeElement;
      const video = this.videoElement.nativeElement;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    }
  }

  private onWindowResize() {
    this.ngZone.run(() => {
      this.checkViewportSize();
      this.adjustWebcamContainerSize();
      this.adjustCanvasSize();
      this.changeDetectorRef.detectChanges();
      this.resizeService.logDimensionsWebcamOn(
        this.platformId,
        this.videoElement?.nativeElement,
        this.canvasElement?.nativeElement,
        this.webcamContainer?.nativeElement,
        this.isLargeViewport,
        this.detectionMode
      );
    });
  }


  private destroyResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }




   // New method for the small viewport button
   closeModalForSmallViewport(): void {
    if (this.uploadSuccess()) {
      this.uploadSuccess.set(false);
      this.closeModal.emit('blobDeleted');
    }
  }


  async loadSoundProfiles() {
    try {
      const result = await this.soundProfileService.loadSoundProfiles();
      console.log('Sound profiles loaded:', result);
      // Check if the blow profile is actually loaded
      const currentBlowProfile = this.audioDetectionService.getCurrentBlowProfile();
      console.log('Current blow profile after loading:', currentBlowProfile);
    } catch (error) {
      console.error('Error loading sound profiles:', error);
    }
  }

  async clearSoundProfiles() {
    try {
      await this.soundProfileService.clearSoundProfiles();
      this.toastService.showToast('All sound profiles cleared', 'success');
    } catch (error) {
      console.error('Error clearing sound profiles:', error);
      this.toastService.showToast('Failed to clear sound profiles', 'error');
    }
  }

  private updateRecordingCanvas(): void {
    if (this.isRecording && this.recordingCanvas && this.videoElement) {
      const ctx = this.recordingCanvas.getContext('2d');
      const video = this.videoElement.nativeElement;
      if (ctx && video.videoWidth > 0 && video.videoHeight > 0) {
        ctx.drawImage(video, 0, 0, this.recordingCanvas.width, this.recordingCanvas.height);
      }
      requestAnimationFrame(() => this.updateRecordingCanvas());
    }
  }

  async startRecording(): Promise<void> {
    if (!this.canvasElement || !this.stream) {
      console.error('Canvas element or stream not found');
      return;
    }

    const canvas = this.canvasElement.nativeElement;
    const videoStream = canvas.captureStream(30); // 30 FPS, adjust as needed

    // Get the audio track from the original stream
    const audioTrack = this.stream.getAudioTracks()[0];
    if (!audioTrack) {
      console.error('No audio track found in the original stream');
      return;
    }

    // Combine video and audio tracks
    const combinedStream = new MediaStream([...videoStream.getVideoTracks(), audioTrack]);

    try {
      await this.recordingService.startRecording(combinedStream);
      this.isRecording = true;
      this.recordingStarted.emit();
      this.recordingTimerService.startTimer(180); // Start the timer with 3 minutes
      // Start the canvas animation loop if it's not already running
      if (!this.animationFrameId) {
        this.animateCanvas();
      }
    } catch (error: unknown) {
      console.error('Error starting recording:', error);
      this.toastService.showToast('Failed to start recording', 'error');
    }
  }


  resetTimer(): void {
    this.remainingTime = 180; // Reset to 3 minutes (180 seconds)
    this.recordingTimerService.resetTimer();
  }

 

  async stopRecording(): Promise<void> {
    console.group('WebcamComponent: stopRecording');
    const logs: string[] = [];

    try {
      logs.push('Stopping recording');

      // Stop the actual recording
      const blob = await this.recordingService.stopRecording();
      logs.push('Recording stopped');

      if (!blob) {
        throw new Error('No blob received after stopping recording');
      }
      logs.push(`Blob received: ${blob.size} bytes`);

      this.isRecording = false;
      this.isPaused = false;
      this.isRecordingStopped.set(true);
      this.recordedBlob = blob;

      // Stop the timer
      this.recordingTimerService.stopTimer();

      // Handle any post-recording UI updates
      this.updateUIAfterRecording();

    } catch (error) {
      logs.push(`Error in stopping recording: ${error instanceof Error ? error.message : 'Unknown error'}`);
      console.error('Error in stopRecording:', error);
    } finally {
      // Log debug info
      await this.debugLogger.logDebugInfo('stopRecording', logs);
      console.groupEnd();
    }
  }

  private updateUIAfterRecording(): void {
    // Implement any UI updates needed after recording stops
    // For example:
    this.showSuccessCard = true;
    this.changeDetectorRef.detectChanges();
  }

  private adjustWebcamSize(): void {
    const container = this.elementRef.nativeElement.querySelector('.webcam-container');
    const canvas = this.canvasElement.nativeElement;

    if (container && canvas) {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    }
  }

  private stopAudioDetection(): void {
    // Instead of calling a non-existent stopAudioDetection method,
    // we'll set the webcamStarted flag to false, which should stop the audio detection
    this.audioDetectionService.setWebcamStarted(false);

    // If there's an active subscription to audio detection, unsubscribe from it
    if (this.audioSubscription) {
      this.audioSubscription.unsubscribe();
      this.audioSubscription = null;
    }
  }

  stopWebcamStream() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  private updateRecordingProgress(): void {
    if (this.isRecording && !this.isPaused) {
      const elapsedTime = Date.now() - this.recordingStartTime;
      const progress = (elapsedTime / (180 * 1000)) * 100; // Assuming 180 seconds max recording time
      this.recordingProgress = Math.min(progress, 100);
      requestAnimationFrame(() => this.updateRecordingProgress());
    }
  }

  pauseRecording(): void {
    this.recordingService.pauseRecording();
    this.isPaused = true;
  }

  resumeRecording(): void {
    this.recordingService.resumeRecording();
    this.isPaused = false;
  }

  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  showConfirmModal(message: string, onConfirm: () => void): void {
    this.confirmMessage = message;
    this.onConfirm = onConfirm;
    this.confirmModal.nativeElement.showModal();
  }

  confirmAction(): void {
    this.confirmModal.nativeElement.close();
    if (this.onConfirm) {
      this.onConfirm();
    }
    this.resetRecordingState();
    this.remainingTime = 180;
  }

  cancelAction(): void {
    this.confirmModal.nativeElement.close();
  }

  private resetRecordingState(): void {
    this.isRecording = false;
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.lastFrame = null;
    // Add any other properties that need to be reset
  }

  async retrieveVideoFromIndexedDB(): Promise<Blob | null> {
    const db = await openDB('VideoStorage', 1);
    const video = await db.get('videos', 'recordedVideo');
    return video || null;
  }

  nextDemoStep(): void {
    if (this.demoStep === 1) {
      this.demoStep = 2;
      this.showBlowingCard = true;
      setTimeout(() => {
        this.showBlowingCard = false;
        this.changeDetectorRef.detectChanges();
      }, 5000);
    } else {
      this.demoStepCompleted = true;
    }
  }

  requestWebcamPermissions() {
    if (isPlatformBrowser(this.platformId) && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          const videoElement = document.querySelector('video');
          if (videoElement) {
            videoElement.srcObject = stream;
          }
        })
        .catch(err => {
          console.error('Error accessing webcam: ', err);
        });
    } else {
      console.error('MediaDevices API not supported in this browser.');
    }
  }

  handleImage(event: any) {
    this.webcamImage = event;
  }

  onSave() {
    this.saveVideo.emit();
  }

  onSend() {
    this.sendVideo.emit();
  }

  saveRecording() {
    if (this.videoBlob) {
      const url = URL.createObjectURL(this.videoBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'recording.webm';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
    this.stopModal.nativeElement.close();
  }

  getRandomPoster(): string {
    const posters = ['background-video01.png', 'background-video02.png'];
    return `assets/images/${posters[Math.floor(Math.random() * posters.length)]}`;
  }

  onVideoRecorded(blob: Blob) {
    this.videoBlob = blob;
  }

  resetComponent() {
    // Implement any reset logic if needed
  }

  async saveVideoAndReturn(action: 'keep' | 'discard' | 'delete'): Promise<void> {
    if (action === 'keep') {
      if (this.recordedBlob) {
        console.log('WebcamComponent: Saving video, blob size:', this.recordedBlob.size);
        this.isUploading = true;
        this.isPreparingForEditing = true;
        this.preparingProgress = 0;

        const progressInterval = setInterval(() => {
          this.preparingProgress += 10;
          if (this.preparingProgress >= 100) {
            clearInterval(progressInterval);
            this.finalizeSaveVideo();
          }
        }, 200);

        try {
          const result = await this.recordingService.saveRecording(
            this.userService.user()?.id,
            'webcam',
            this.recordedBlob
          );
          console.log('WebcamComponent: Save result:', result);
          if (result.success && result.data) {
            this.toastService.showToast('Video saved successfully!', 'success');
            this.saveVideo.emit({
              downloadURL: result.data.downloadURL,
              filename: result.data.filename
            });
            this.uploadSuccess.set(true);
          } else {
            this.toastService.showToast('Failed to save video.', 'error');
          }
        } catch (error) {
          console.error('WebcamComponent: Error saving video:', error);
          this.toastService.showToast('An error occurred while saving the video.', 'error');
        } finally {
          this.isUploading = false;
          this.isPreparingForEditing = false;
        }
      } else {
        this.toastService.showToast('No video recorded to save.', 'error');
      }
    } else if (action === 'discard' || action === 'delete') {
      this.discardAndCloseModal();
    }

    this.stopWebcam();
    this.clearRecordedData();
    this.ensureWebcamFullyStopped();

    console.log('WebcamComponent: Closing modal and returning to video details');
    this.closeModal.emit(new Event('blobDeleted'));
  }

  private finalizeSaveVideo(): void {
    // Actual saving logic here
    // For example:
    // this.recordingService.saveRecording(...);

    this.isPreparingForEditing = false;
    this.preparingProgress = 0;
    // Additional logic to handle saved video (e.g., navigate to editing page)
  }

  private resetWebcamView(): void {
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.lastFrame = null;
    this.isRecording = false;
    this.isPaused = false;
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.remainingTime = 180; // Reset to 3 minutes

    // Ensure the camera is visible
    this.cameraVisible = true;

    // Reset any other relevant properties
    this.showSuccessCard = false;

    // Redraw the canvas
    this.drawToCanvas();

    // Emit an event to notify parent components if necessary
    this.recordingDeleted.emit();
  }

  cancelSaveOptions() {
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
  }

  closeModalAction(): void {
    if (this.isClosing) {
      console.log('WebcamComponent: Already closing, ignoring duplicate call');
      return;
    }

    this.isClosing = true;
    console.group('WebcamComponent: Close Modal Action');
    console.log('Close/Delete button clicked');

    // Ensure webcam and mic are fully stopped
    this.ensureWebcamAndMicFullyStopped();

    if (this.recordedBlob) {
      const blobIdentifier = this.recordedBlob.size + '_' + new Date().getTime();
      console.log(`Deleting recorded blob. Identifier: ${blobIdentifier}`);

      // Use the RecordingService to clear the recording
      this.recordingService.clearRecording();

      this.recordedBlob = null;

      // Clear any local storage if used
      localStorage.removeItem('tempRecordedVideo');

      console.log('Recorded blob and local storage cleared');

      // Emit the event after clearing
      this.closeModal.emit(new Event('blobDeleted'));
    } else {
      console.log('No recorded blob to delete');
      // Emit a different event if no blob was deleted
      this.closeModal.emit(new Event('modalClosed'));
    }

    console.trace('Trace for closeModalAction');
    console.groupEnd();

    if (this.detectionMode) {
      this.resetAndClose();
    } else {
      this.stopWebcam();
    }

    // Reset the recording state
    this.isRecordingStopped.set(false);
    this.resetWebcamView();

    this.isClosing = false;
  }

  private resetAndClose(): void {
    if (this.isRecording) {
      this.stopRecording();
    }
    this.stopWebcam();
    this.clearRecordedData();
    this.cancelAnimationFrame();
    this.closeModal.emit(new Event('blobDeleted'));
  }

  private clearRecordedData(): void {
    this.videoBlob = null;
    this.recordedBlob = null;
    this.isRecordingStopped.set(false);
    this.isRecording = false;
  }

  toggleCameraVisibility(): void {
    this.cameraVisible = !this.cameraVisible;
    if (this.videoElement && this.videoElement.nativeElement) {
      if (this.cameraVisible) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    }
    this.drawToCanvas();
  }

  private startCamera(): void {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = true;
      }
      this.videoElement.nativeElement.srcObject = this.stream;
      this.videoElement.nativeElement.style.opacity = '1';
    } else {
      // If stream doesn't exist, reinitialize it
      this.initializeWebcam();
    }
  }

  private stopCamera(): void {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = false;
      }
      this.videoElement.nativeElement.style.opacity = '0';
    }
  }

  private initializeWebcam(): void {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        this.stream = stream;
        this.videoElement.nativeElement.srcObject = stream;
        this.videoElement.nativeElement.style.opacity = '1';
      })
      .catch(error => {
        console.error('Error accessing media devices.', error);
      });
  }

  private async loadAvailableEffects() {
    const configDoc = doc(this.firestore, 'config', 'modalEffects');
    try {
      const docSnapshot = await getDoc(configDoc);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        this.availableEffects = Object.entries(data)
          .filter(([_, value]) => value === true)
          .map(([key, _]) => this.capitalizeFirstLetter(key) as 'Bubbles' | 'Pinwheel' | 'Music');
      }
    } catch (err) {
      console.error('Error loading available effects:', err);
    }
  }

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getEffectEmoji(effect: string): string {
    switch (effect.toLowerCase()) {
      case 'bubbles':
        return '🫧';
      case 'pinwheel':
        return '🎡';
      case 'music':
        return '🎵';
      default:
        return '';
    }
  }

  getPreviousLevel(index: number): number {
    return this.previousLevels()[index] || 0;
  }

  onModeSelected(mode: 'Bubbles' | 'Pinwheel' | 'Music') {
    this.isAnimating = true;
    this.modeSelected.emit(mode);
    this.selectedMode = mode;
  }



  getSelectedMotivatorWithEmoji(): string {
    return `${this.getEffectEmoji(this.selectedMotivator)} ${this.selectedMotivator}`;
  }



  selectMode(mode: 'Bubbles' | 'Pinwheel' | 'Music') {
    this.selectedMode = mode;
    this.selectedMotivator = mode;
    this.closeMotivatorDropdown();
  }

  closeMotivatorDropdown() {
    if (this.motivatorDropdown && this.motivatorDropdown.nativeElement) {
      this.motivatorDropdown.nativeElement.open = false;
    }
  }

  private handlePositivePatternDetected() {
    console.log('Positive pattern detected!');
    this.consecutivePositivePatterns++;

    if (this.consecutivePositivePatterns >= 3) {
      this.showBlowingCard = false;
      
      this.changeDetectorRef.detectChanges();
    }
  }

  private resetConsecutivePatterns() {
    this.consecutivePositivePatterns = 0;
  }

  private muteAudioOutput(): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.muted = true;
    }
  }

  private cancelAnimationFrame(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  async initializeForRecording(): Promise<void> {
    console.log('WebcamComponent: initializeForRecording called');

    this.isRecording = false;
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.lastFrame = null;
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.remainingTime = 180; // Reset to 3 minutes
    this.isLoggingTimer = true; // Enable timer logging
    this.hasLoggedTimerDrawing = false; // Reset this flag

    // Ensure the camera is visible
    this.cameraVisible = true;

    // Reset any other relevant properties
    this.showSuccessCard = false;

    // Activate the camera and start drawing to canvas
    await this.startWebcam();
    // Force a redraw of the canvas
    this.drawToCanvas();

    // Add a small delay to ensure the canvas is ready
    await new Promise(resolve => setTimeout(resolve, 100));

    // Check canvas visibility and log its state

    // Ensure the canvas is properly initialized
    this.initializeCanvas();


    // Check canvas visibility and log its state
    this.checkCanvasVisibility();

    console.log('WebcamComponent: Canvas dimensions:', this.canvasElement.nativeElement.width, 'x', this.canvasElement.nativeElement.height);
    console.log('WebcamComponent: Video dimensions:', this.videoElement.nativeElement.videoWidth, 'x', this.videoElement.nativeElement.videoHeight);


    console.log('WebcamComponent: Canvas dimensions:', this.canvasElement.nativeElement.width, 'x', this.canvasElement.nativeElement.height);
    console.log('WebcamComponent: Video dimensions:', this.videoElement.nativeElement.videoWidth, 'x', this.videoElement.nativeElement.videoHeight);

    // Initialize the recording canvas
    this.recordingCanvas = document.createElement('canvas');
    const videoElement = this.videoElement.nativeElement;
    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
    this.recordingCanvas.width = 1280; // Set to desired width
    this.recordingCanvas.height = Math.round(1280 / aspectRatio);

    console.log('WebcamComponent: Recording canvas created:', this.recordingCanvas ? 'Yes' : 'No');

    if (this.canvasElement && this.videoElement) {
      console.log('WebcamComponent: Canvas dimensions:', this.canvasElement.nativeElement.width, 'x', this.canvasElement.nativeElement.height);
      console.log('WebcamComponent: Video dimensions:', this.videoElement.nativeElement.videoWidth, 'x', this.videoElement.nativeElement.videoHeight);

      // Initialize the recording canvas
      this.recordingCanvas = document.createElement('canvas');
      // We'll set the dimensions in startActualRecording when we're sure the video is ready

      console.log('WebcamComponent: Recording canvas created:', this.recordingCanvas ? 'Yes' : 'No');
    } else {
      console.error('WebcamComponent: Canvas or video element is not initialized');
    }
  }

  private checkCanvasVisibility() {
    if (this.canvasElement && this.canvasElement.nativeElement) {
      const canvas = this.canvasElement.nativeElement;
      const isVisible = canvas.offsetParent !== null;
      console.log('WebcamComponent: Canvas visibility:', isVisible);
      console.log('WebcamComponent: Canvas dimensions:', canvas.width, 'x', canvas.height);
      console.log('WebcamComponent: Canvas offset dimensions:', canvas.offsetWidth, 'x', canvas.offsetHeight);
      console.log('WebcamComponent: Canvas style:', canvas.style.cssText);
      console.log('WebcamComponent: Canvas parent style:', canvas.parentElement?.style.cssText);
    } else {
      console.log('WebcamComponent: Canvas element not found');
    }
  }

  stopWebcam(): void {
    console.log('WebcamComponent: Stopping webcam and microphone');
    this.ensureWebcamAndMicFullyStopped();
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.demoLaunched = false;
    this.demoStep = 1;
    this.demoStepCompleted = false;
    this.showBlowingCard = false;
    this.moveUp = false;
    this.resetRecordingState();
    this.remainingTime = 180;
    this.stopAudioDetection();
    this.isAnimating = false;

    this.cancelAnimationFrame();
    this.adjustWebcamSize();
    this.changeDetectorRef.detectChanges();    
    this.resetConsecutivePatterns();
    this.showKeepBlowingCard = false;
    


    // Reset fullscreen mode
    this.isFullscreenVideo.next(false);

    // Reset container height when stopping webcam
    if (this.webcamContainer && this.webcamContainer.nativeElement) {
      this.webcamContainer.nativeElement.style.height = '';
    }

    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
      // Only add blur class when webcam is stopped
      if (!this.webcamStarted) {
        this.videoElement.nativeElement.classList.add('blur-md');
      }
    }

    console.log('WebcamComponent: Webcam and microphone stopped');
  }




  closeTriggerAbsenceCard(): void {
    this.showTriggerAbsenceCard = false;
    this.changeDetectorRef.detectChanges();
  }


  private startDrawingToCanvas(): void {
    const drawFunction = () => {
      this.canvasDrawService.drawToCanvas(
        this.canvasElement.nativeElement,
        this.videoElement.nativeElement,
        this.drawDimensions,
        this.detectionMode,
        this.formatTime,
        this.cameraVisible,
        this.remainingTime
      );
    };
    this.canvasDrawService.startDrawLoop(drawFunction);
  }
  
  async startWebcam(): Promise<void> {
    console.group('WebcamComponent: startWebcam');
    try {
      const stream = await this.webcamControlService.startWebcam();
      await this.handleStreamSuccess(stream);
      console.log('Webcam started successfully');
      this.webcamStarted = true;
      this.initializeCanvas();
      
      // After webcam is started, adjust sizes
      this.adjustSizes();
      
      // Start drawing to canvas
      this.startDrawingToCanvas();
    } catch (error) {
      console.error('Error in startWebcam:', error);
      // Handle the error (e.g., show a message to the user)
    } finally {
      console.groupEnd();
    }
  }

  private async handleStreamSuccess(stream: MediaStream): Promise<void> {
    this.stream = stream;
    if (this.videoElement && this.videoElement.nativeElement) {
      console.log('Before setting srcObject:', this.getElementDimensions(this.videoElement.nativeElement));
      this.videoElement.nativeElement.srcObject = stream;
      // Comment out or remove this line to allow audio recording
      // this.webcamControlService.muteAudioOutput(this.videoElement.nativeElement);
      console.log('After setting srcObject:', this.getElementDimensions(this.videoElement.nativeElement));
      
      await this.videoElement.nativeElement.play();
      console.log('After play():', this.getElementDimensions(this.videoElement.nativeElement));

      // Wait for the video to have its dimensions
      await new Promise<void>((resolve) => {
        const checkDimensions = () => {
          if (this.videoElement.nativeElement.videoWidth > 0) {
            console.log('Video dimensions available:', this.getElementDimensions(this.videoElement.nativeElement));
            resolve();
          } else {
            requestAnimationFrame(checkDimensions);
          }
        };
        checkDimensions();
      });

      this.webcamStarted = true;
      this.webcamActive.set(true);
      this.webcamActiveChange.emit(true);
      this.recordingStarted.emit();
      this.showSuccessCard = false;

      if (this.demoMode) {
        this.isAnimating = true;
        this.demoLaunched = true;
      }

      try {
        await this.loadSoundProfiles();
        await this.startAudioDetection(stream);
      } catch (error) {
        console.error('Error initializing audio detection:', error);
      }

      this.muteAudioOutput();

      // Ensure the video element is visible before adjusting sizes
      this.videoElement.nativeElement.style.display = 'block';

      // Force a reflow to ensure the video element is rendered
      this.videoElement.nativeElement.offsetHeight;

      this.adjustSizes();
      console.log('After adjustSizes:', this.getElementDimensions(this.videoElement.nativeElement));

      this.changeDetectorRef.detectChanges();
      console.log('After detectChanges:', this.getElementDimensions(this.videoElement.nativeElement));

      // Start drawing to canvas immediately
      this.drawToCanvas();
      // Log dimensions after webcam has started
      this.logDimensions();

      // Start audio detection after stream is set up
      await this.startAudioDetection(stream);

      // Log dimensions again after a short delay
      setTimeout(() => this.logDimensions(), 1000);
    }
  }

  private getElementDimensions(element: HTMLElement) {
    return {
      offsetWidth: element.offsetWidth,
      offsetHeight: element.offsetHeight,
      clientWidth: element.clientWidth,
      clientHeight: element.clientHeight,
      style: {
        width: element.style.width,
        height: element.style.height
      }
    };
  }

  private adjustSizes(): void {
    console.group('WebcamComponent: adjustSizes');
    if (this.webcamContainer && this.videoElement && this.canvasElement) {
      const containerElement = this.webcamContainer.nativeElement;
      const videoElement = this.videoElement.nativeElement;
      const canvasElement = this.canvasElement.nativeElement;

      // Adjust container size
      this.resizeService.adjustWebcamContainerSize(containerElement);

      // Adjust video size
      this.resizeService.adjustVideoSize(videoElement, containerElement);

      // Log final dimensions
      console.log('Final container dimensions:', containerElement.clientWidth, 'x', containerElement.clientHeight);
      console.log('Final video dimensions:', videoElement.clientWidth, 'x', videoElement.clientHeight);

      // Set canvas size to match video size
        canvasElement.width = videoElement.clientWidth;
        canvasElement.height = videoElement.clientHeight;
        console.log('Canvas dimensions set to:', canvasElement.width, 'x', canvasElement.height);

      // Set setup as complete
      this.resizeService.setSetupComplete(true);
    } else {
      console.log('Some elements are not available for resizing');
    }
    console.groupEnd();
  }

  private logDimensions(): void {
    setTimeout(() => {
      this.resizeService.logDimensionsWebcamOn(
        this.platformId,
        this.videoElement?.nativeElement,
        this.canvasElement?.nativeElement,
        this.webcamContainer?.nativeElement,
        this.isLargeViewport,
        this.detectionMode
      );
    }, 0);
  }

  private async startAudioDetection(stream: MediaStream): Promise<void> {
    console.group('WebcamComponent: startAudioDetection');
    try {
      console.log('Initiating audio detection with stream:', stream);
      await this.audioDetectionService.startAudioDetection(stream);
      this.audioDetectionService.setWebcamStarted(true);
      
      await this.soundProfileService.loadAndConfigureSoundProfiles();
      console.log('Audio detection started successfully');
    } catch (error) {
      console.error('Failed to start audio detection:', error);
    } finally {
      console.groupEnd();
    }
  }



  private adjustWebcamContainerSize(): void {
    if (this.webcamContainer?.nativeElement) {
      this.resizeService.adjustWebcamContainerSize(this.webcamContainer.nativeElement);
    }
  }

  private adjustCanvasSize(): void {
    if (this.videoElement?.nativeElement && this.canvasElement?.nativeElement && this.webcamContainer?.nativeElement) {
      const { drawWidth, drawHeight, xOffset, yOffset } = this.resizeService.adjustCanvasSize(
        this.canvasElement.nativeElement,
        this.videoElement.nativeElement,
        this.webcamContainer.nativeElement
        
      );

      // Update drawDimensions and trigger change detection
      this.drawDimensions = { drawWidth, drawHeight, xOffset, yOffset };
      this.changeDetectorRef.detectChanges();
    }
  }

  discardAndCloseModal(): void {
    this.resetRecording();
    this.discardRecording.emit();
    this.closeModal.emit(new Event('discarded'));
  }

  private resetRecording(): void {
    this.isRecording = false;
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.recordingService.resetRecording();
    this.recordingTimerService.resetTimer();
  }

  private removeBlurClass(): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.classList.remove('blur-md');
    }
  }

  ngDoCheck() {
    if (this.webcamStarted && this.videoElement && this.videoElement.nativeElement) {
      // Ensure blur class is removed when webcam is on
      this.removeBlurClass();
    }
  }

  private ensureWebcamFullyStopped(): void {
    console.log('Ensuring webcam is fully stopped');
    
    // Stop the webcam stream
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
      this.stream = null;
    }

    // Stop any ongoing recording
    this.recordingService.stopRecording();

    // Reset webcam state
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.webcamActiveChange.emit(false);

    // Clear video source
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
    }

    // Stop audio detection
    this.stopAudioDetection();

    // Reset other related states
    this.isRecording = false;
    this.isPaused = false;
    this.demoLaunched = false;
    this.isAnimating = false;

    // Emit event to notify parent components
    this.closeModal.emit(new Event('webcamFullyStopped'));

    console.log('Webcam fully stopped');
  }

  private ensureWebcamAndMicFullyStopped(): void {
    console.log('Ensuring webcam and microphone are fully stopped');
    
    // Stop all tracks in the stream
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
        console.log(`Stopped track: ${track.kind}`);
      });
      this.stream = null;
    }

    // Stop any ongoing recording
    this.recordingService.stopRecording();

    // Reset webcam state
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.webcamActiveChange.emit(false);

    // Clear video source
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
    }

    // Stop audio detection
    this.stopAudioDetection();

    // Reset other related states
    this.isRecording = false;
    this.isPaused = false;
    this.demoLaunched = false;
    this.isAnimating = false;

    // Emit event to notify parent components
    this.closeModal.emit(new Event('webcamAndMicFullyStopped'));

    console.log('Webcam and microphone fully stopped');
  }



  public forceStopWebcam(): void {
    this.ensureWebcamAndMicFullyStopped();
  }


}