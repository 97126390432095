import { initializeApp } from 'firebase/app';
import { Firestore, getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { Auth, getAuth, connectAuthEmulator } from 'firebase/auth';
import { FirebaseStorage, getStorage, connectStorageEmulator } from 'firebase/storage';
import { environment } from '@environments/environment';

const app = initializeApp(environment.firebaseConfig);

const firestore: Firestore = getFirestore(app);
const auth: Auth = getAuth(app);
const storage: FirebaseStorage = getStorage(app);

if (environment.useEmulators) {
  console.log('Connecting to Firebase emulators');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  console.log('Firestore emulator connected on localhost:8080');
  connectAuthEmulator(auth, 'http://localhost:9099');
  console.log('Auth emulator connected on http://localhost:9099');
  connectStorageEmulator(storage, 'localhost', 9199);
  console.log('Storage emulator connected on localhost:9199');
  console.log('Firebase emulators connected');
}

export function getFirestoreInstance(): Firestore {
  return firestore;
}

export function getAuthInstance(): Auth {
  return auth;
}

export function getStorageInstance(): FirebaseStorage {
  return storage;
}

export { app, firestore, auth, storage };