import { Injectable, signal, computed, effect } from '@angular/core';
import { Firestore, doc, getDoc, setDoc, onSnapshot, collection, query, orderBy, limit, getDocs, Timestamp, addDoc, updateDoc, where } from '@angular/fire/firestore';
import { UnifiedSoundProfile } from '@models/sound-profile.model';
import { UserService } from '@services/user.service';

import * as FFT from 'fft-js';

const DEBUG_MODE = false; // Set this to true to enable logging

@Injectable({
  providedIn: 'root'
})
export class AudioDetectionService {
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private microphone: MediaStreamAudioSourceNode | null = null;
  private dataArray: Uint8Array | null = null;
  private animationFrameId: number | null = null;
  private positiveProfiles: UnifiedSoundProfile[] = [];
  private negativeProfiles: UnifiedSoundProfile[] = [];

  private audioLevels: number[] = [];
  private audioLevelSignal = signal<number>(0);
  audioLevel = computed(() => this.audioLevelSignal());

  private noiseDetectedSignal = signal<boolean>(false);
  noiseDetected = computed(() => this.noiseDetectedSignal());

  private _threshold = signal(0.0001);
  private _acceleration = signal(1);

  threshold = this._threshold.asReadonly();
  acceleration = this._acceleration.asReadonly();

  private breathDetectedSignal = signal<boolean>(false);
  breathDetected = computed(() => this.breathDetectedSignal());

  private breathingHistory: boolean[] = [];
  private readonly HISTORY_LENGTH = 10;
  private readonly BREATH_DURATION_THRESHOLD = 2; // Lowered from 3

  private ambientEnergyLevel = 0;
  private readonly CALIBRATION_DURATION = 3000; // 3 seconds

  private calibratedProfiles: UnifiedSoundProfile[] = [];

  private blowProfile = signal<UnifiedSoundProfile | null>(null);
  private antiBreathProfiles = signal<UnifiedSoundProfile[]>([]);

  private positivePatternDetectedSignal = signal<boolean>(false);
  positivePatternDetected = computed(() => this.positivePatternDetectedSignal());

  private isInitialized = false;
  private isWebcamStarted = false;

  constructor(
    private firestore: Firestore,
    private userService: UserService
  ) {
    this.loadSettings();
    effect(() => {
      const profile = this.blowProfile();
      if (profile) {
        this.log('Blow profile updated, reconfiguring audio analysis');
        this.reconfigureAudioAnalysis(profile);
      }
    });
  }

  private reconfigureAudioAnalysis(profile: UnifiedSoundProfile) {
    // Implement the logic to reconfigure audio analysis based on the new profile
    // This might involve updating thresholds, patterns, etc.
  }

  async initAudio(stream: MediaStream): Promise<void> {
    if (!stream) {
      throw new Error('Invalid media stream provided');
    }

    try {
      this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 2048;
      
      const source = this.audioContext.createMediaStreamSource(stream);
      source.connect(this.analyser);

      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

      this.isInitialized = true;

      // Start the audio analysis loop
      this.startAudioAnalysis();
    } catch (error) {
      this.log('Error initializing audio:', error);
      throw error;
    }
  }

  async calibrate(): Promise<void> {
    this.log('Starting calibration...');
    return new Promise((resolve) => {
      let samples = 0;
      let totalEnergy = 0;
      const startTime = Date.now();

      const calibrationInterval = setInterval(() => {
        if (this.analyser && this.dataArray) {
          this.analyser.getByteFrequencyData(this.dataArray);
          const energy = this.calculateTotalEnergy(this.dataArray);
          totalEnergy += energy;
          samples++;
        }

        if (Date.now() - startTime >= this.CALIBRATION_DURATION) {
          clearInterval(calibrationInterval);
          this.ambientEnergyLevel = totalEnergy / samples;
          this.log('Calibration complete. Ambient energy level:', this.ambientEnergyLevel);
          resolve();
        }
      }, 100);
    });
  }

  private detectPositivePattern(frequencyData: Uint8Array | number[]): boolean {
    const data = this.convertToNumberArray(frequencyData);
    const blowProfile = this.blowProfile();
    if (!blowProfile || !blowProfile.fftMagnitudes) {
      return false;
    }

    const fftResult = FFT.fft(data);
    const magnitudes = FFT.util.fftMag(fftResult);
    
    const similarity = this.calculateFFTSimilarity(magnitudes, blowProfile.fftMagnitudes);
    const similarityThreshold = 0.8; // Adjust this value as needed

    return similarity > similarityThreshold;
  }

  private calculateFFTSimilarity(fft1: number[], fft2: number[]): number {
    const minLength = Math.min(fft1.length, fft2.length);
    let dotProduct = 0;
    let magnitude1 = 0;
    let magnitude2 = 0;

    for (let i = 0; i < minLength; i++) {
      dotProduct += fft1[i] * fft2[i];
      magnitude1 += fft1[i] * fft1[i];
      magnitude2 += fft2[i] * fft2[i];
    }

    return dotProduct / (Math.sqrt(magnitude1) * Math.sqrt(magnitude2));
  }

  private startAudioAnalysis(): void {
    if (!this.isInitialized) {
      this.log('Audio not initialized, skipping analysis');
      return;
    }

    const analyzeAudio = () => {
      this.animationFrameId = requestAnimationFrame(analyzeAudio);
      
      if (this.analyser && this.dataArray) {
        this.analyser.getByteFrequencyData(this.dataArray);
        
        const level = this.calculateLevel(this.dataArray);
        const isBreathing = this.detectBreathing(this.convertToNumberArray(this.dataArray));
        const isPositivePatternDetected = this.detectPositivePattern(this.dataArray);

        this.audioLevels.unshift(level);
        this.audioLevels = this.audioLevels.slice(0, 20);
        this.audioLevelSignal.set(level);
        this.breathDetectedSignal.set(isBreathing);
        this.positivePatternDetectedSignal.set(isPositivePatternDetected);
        this.detectNoise(level);

        this.log('Audio level:', level, 'Breath detected:', isBreathing, 'Positive pattern detected:', isPositivePatternDetected);
      }
    };

    analyzeAudio();
    this.log('Audio analysis loop started');
  }

  stopAudio(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
    this.audioContext = null;
    this.analyser = null;
    this.microphone = null;
    this.dataArray = null;
    this.audioLevelSignal.set(0);
    this.noiseDetectedSignal.set(false);
    this.breathDetectedSignal.set(false);
    this.isInitialized = false;
  }

  private detectNoise(level: number): void {
    const adjustedLevel = level * this.acceleration();
    if (adjustedLevel > this.threshold() / 100) {
      this.noiseDetectedSignal.set(true);
    } else {
      this.noiseDetectedSignal.set(false);
    }
  }

  private loadSettings() {
    const configDoc = doc(this.firestore, 'config', 'soundSettings');
    onSnapshot(configDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        this._threshold.set(data['threshold'] || 0.0001);
        this._acceleration.set(data['acceleration'] || 1);
        this.log('Audio settings loaded:', data);
      }
    });
  }

  updateSettings(newThreshold: number, newAcceleration: number) {
    this._threshold.set(newThreshold);
    this._acceleration.set(newAcceleration);
  }

  async startCalibration(): Promise<void> {
    return new Promise((resolve) => {
      let maxLevel = 0;
      const calibrationDuration = 5000; // 5 seconds
      const startTime = Date.now();

      const calibrationInterval = setInterval(() => {
        const currentLevel = this.audioLevel();
        maxLevel = Math.max(maxLevel, currentLevel);

        if (Date.now() - startTime >= calibrationDuration) {
          clearInterval(calibrationInterval);
          this._threshold.set(maxLevel * 100 * 1.2); // Set threshold 20% higher than max observed level
          this._acceleration.set(1); // Reset acceleration to default
          this.updateFirestoreSettings();
          resolve();
        }
      }, 100);
    });
  }

  private async updateFirestoreSettings() {
    const configDoc = doc(this.firestore, 'config', 'soundSettings');
    await setDoc(configDoc, { 
      threshold: this._threshold(), 
      accelereration: this._acceleration() 
    });
  }

  public isBreathDetected(currentLevel: number, peakFrequency: number): boolean {
    const now = Date.now();
    const minBreathDuration = 500; // milliseconds
    const maxBreathDuration = 3000; // milliseconds
    const breathFrequencyRange = { min: 100, max: 1000 }; // Hz

    const blowProfileValue = this.blowProfile();
    if (blowProfileValue && currentLevel > blowProfileValue.threshold && 
        peakFrequency > breathFrequencyRange.min && 
        peakFrequency < breathFrequencyRange.max) {
      if (!this.breathState.isBreathing) {
        this.breathState.isBreathing = true;
        this.breathState.breathStartTime = now;
      }
      this.breathState.breathDuration = now - this.breathState.breathStartTime;
    } else if (this.breathState.isBreathing) {
      if (this.breathState.breathDuration > minBreathDuration && 
          this.breathState.breathDuration < maxBreathDuration) {
        this.breathState.isBreathing = false;
        this.breathState.breathDuration = 0;
        return true;
      }
      this.breathState.isBreathing = false;
      this.breathState.breathDuration = 0;
    }

    return false;
  }

  public analyzeFrequency(audioData: Float32Array, sampleRate: number): number {
    const fft = FFT.fft(Array.from(audioData));
    const magnitudes = FFT.util.fftMag(fft);
    const maxIndex = magnitudes.indexOf(Math.max(...magnitudes));
    return maxIndex * sampleRate / audioData.length;
  }

  private calculateTotalEnergy(fftData: Uint8Array | number[]): number {
    const data = this.convertToNumberArray(fftData);
    return data.reduce((sum, value) => sum + value * value, 0);
  }

  private calculateLevel(fftData: Uint8Array | number[]): number {
    const data = this.convertToNumberArray(fftData);
    const sum = data.reduce((acc, val) => acc + val * val, 0);
    return Math.sqrt(sum / data.length) / 255; // Normalize to 0-1 range
  }

  public async reloadSoundProfiles(): Promise<{ positiveProfiles: number, negativeProfiles: number }> {
    try {
      const profilesRef = collection(this.firestore, 'config/soundSettings/soundProfiles');
      const querySnapshot = await getDocs(profilesRef);
      
      this.blowProfile.set(null);
      this.antiBreathProfiles.set([]);

      let blowCount = 0;
      let laughCount = 0;
      let clapCount = 0;
      let helloCount = 0;
      let otherCount = 0;

      this.log('Starting FFT analysis of sound profiles...');
      
      querySnapshot.forEach((doc) => {
        const profile = doc.data() as UnifiedSoundProfile;
        const analyzedProfile = this.analyzeProfileWithFFT(profile);
        
        switch (profile.type) {
          case 'blow':
            this.blowProfile.set(analyzedProfile);
            this.validateAndFixBlowProfile();
            blowCount++;
            break;
          case 'laugh':
            this.antiBreathProfiles.update(profiles => [...profiles, analyzedProfile]);
            laughCount++;
            break;
          case 'clap':
            this.antiBreathProfiles.update(profiles => [...profiles, analyzedProfile]);
            clapCount++;
            break;
          case 'hello':
            this.antiBreathProfiles.update(profiles => [...profiles, analyzedProfile]);
            helloCount++;
            break;
          default:
            this.antiBreathProfiles.update(profiles => [...profiles, analyzedProfile]);
            otherCount++;
        }
      });

      this.log('FFT analysis of sound profiles completed.');

      this.log('Profile counts:');
      this.log('- Blow profiles:', blowCount);
      this.log('- Laugh profiles:', laughCount);
      this.log('- Clap profiles:', clapCount);
      this.log('- Hello profiles:', helloCount);
      this.log('- Other profiles:', otherCount);
      this.log('Total positive profiles:', blowCount);
      this.log('Total negative profiles:', this.antiBreathProfiles().length);

      return {
        positiveProfiles: blowCount,
        negativeProfiles: this.antiBreathProfiles().length
      };
    } catch (error) {
      this.log('Error reloading sound profiles:', error);
      throw error;
    }
  }

  private analyzeProfileWithFFT(profile: UnifiedSoundProfile): UnifiedSoundProfile {
    if (profile.frequencyProfile && profile.frequencyProfile.length > 0) {
      this.log(`Analyzing profile ${profile.id} with FFT...`);
      
      const fftResult = FFT.fft(profile.frequencyProfile);
      const magnitudes = FFT.util.fftMag(fftResult);
      
      const maxMagnitudeIndex = magnitudes.indexOf(Math.max(...magnitudes));
      const dominantFrequency = maxMagnitudeIndex * (this.audioContext?.sampleRate ?? 44100) / profile.frequencyProfile.length;

      this.log(`FFT analysis completed for profile ${profile.id}. Dominant frequency: ${dominantFrequency.toFixed(2)} Hz`);

      return {
        ...profile,
        fftMagnitudes: magnitudes,
        dominantFrequency: dominantFrequency
      };
    }
    this.log(`Profile ${profile.id} does not have frequency data for FFT analysis.`);
    return profile;
  }

  private validateAndFixBlowProfile() {
    this.blowProfile.update(profile => {
      if (!profile) return null;
      return {
        ...profile,
        id: profile.id || 'temp-id',
        frequencyProfile: profile.frequencyProfile || [],
        minLevel: typeof profile.minLevel === 'number' ? profile.minLevel : 0,
        maxLevel: typeof profile.maxLevel === 'number' ? profile.maxLevel : 1,
        threshold: typeof profile.threshold === 'number' ? profile.threshold : 0.5,
      };
    });
  }

  getFrequencyData(): Uint8Array {
    if (!this.analyser) throw new Error('Analyser not initialized');
    const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteFrequencyData(frequencyData);
    return frequencyData;
  }

  calculateRiseTime(data: Uint8Array | number[]): number {
    const dataArray = this.convertToNumberArray(data);
    const peak = Math.max(...dataArray);
    const peakIndex = dataArray.indexOf(peak);
    const riseData = dataArray.slice(0, peakIndex + 1);
    return riseData.length * 20; // 20ms per sample
  }

  calculateFallTime(data: Uint8Array | number[]): number {
    const dataArray = this.convertToNumberArray(data);
    const peak = Math.max(...dataArray);
    const peakIndex = dataArray.indexOf(peak);
    const fallData = dataArray.slice(peakIndex);
    return fallData.length * 20; // 20ms per sample
  }

  calculateConsistency(data: Uint8Array | number[]): number {
    const dataArray = this.convertToNumberArray(data);
    const mean = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
    const variance = dataArray.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / dataArray.length;
    return 1 - Math.sqrt(variance) / mean; // Higher value means more consistent
  }

  public detectBreathing(frequencyData: Uint8Array | number[]): boolean {
    const data = this.convertToNumberArray(frequencyData);
    
    if (!this.blowProfile()) {
      this.log('No blow profile available');
      return false;
    }

    const currentLevel = this.calculateLevel(data);
    const fftResult = FFT.fft(Array.from(data));
    const currentMagnitudes = FFT.util.fftMag(fftResult);

    this.log('Current audio level:', currentLevel);

    // Scoring system
    let score = 0;
    const maxScore = 5;

    // Check if the level is within an acceptable range
    const isWithinRange = currentLevel >= this.blowProfile()!.minLevel * 0.8 && 
                          currentLevel <= this.blowProfile()!.maxLevel * 1.2;
    if (isWithinRange) score++;

    // Check if the level exceeds the threshold
    const exceedsThreshold = currentLevel > this.blowProfile()!.threshold * 0.9;
    if (exceedsThreshold) score++;

    // Compare with blow profile using FFT
    const blowSimilarity = this.calculateFFTSimilarity(currentMagnitudes, this.blowProfile()!.fftMagnitudes || []);
    
    // Compare with anti-breath profiles
    const antiBreathSimilarities = this.antiBreathProfiles().map(profile => 
      this.calculateFFTSimilarity(currentMagnitudes, profile.fftMagnitudes || [])
    );
    const maxAntiBreathSimilarity = Math.max(...antiBreathSimilarities, 0);

    this.log('Blow similarity:', blowSimilarity);
    this.log('Max anti-breath similarity:', maxAntiBreathSimilarity);

    // Check if the sound is more similar to a blow than to any anti-breath profile
    if (blowSimilarity > maxAntiBreathSimilarity * 1.2) {
      score += 2;
    } else {
      // If it's more similar to an anti-breath profile, penalize the score
      score -= 2;
    }

    // Additional score for high blow similarity
    if (blowSimilarity > 0.8) score++;

    this.log('Breath detection score:', score, '/', maxScore);

    // Check duration
    const now = Date.now();
    const minBreathDuration = 300; // ms
    const maxBreathDuration = 3000; // ms

    if (score >= 3) {
      if (!this.breathState.isBreathing) {
        this.breathState.isBreathing = true;
        this.breathState.breathStartTime = now;
      }
      this.breathState.breathDuration = now - this.breathState.breathStartTime;
    } else {
      this.breathState.isBreathing = false;
      this.breathState.breathDuration = 0;
    }

    // Require a high score and appropriate duration for positive detection
    const isValidBreath = score >= 4 && 
                          this.breathState.breathDuration >= minBreathDuration && 
                          this.breathState.breathDuration <= maxBreathDuration;

    if (isValidBreath) {
      this.log('Valid breath detected. Duration:', this.breathState.breathDuration);
    } else {
      this.log('Not a valid breath. Score too low or duration inappropriate.');
    }

    return isValidBreath;
  }

  public calculatePeakFrequency(frequencyData: Uint8Array | number[]): number {
    const data = this.convertToNumberArray(frequencyData);
    const maxIndex = data.indexOf(Math.max(...data));
    return maxIndex * (this.audioContext?.sampleRate ?? 44100) / (2 * data.length);
  }

  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  async calibrateStep(step: 'blow' | 'laugh' | 'clap' | 'hello'): Promise<UnifiedSoundProfile> {
    return new Promise(async (resolve) => {
      await this.initializeAudioContext();
      
      const calibrationData: number[] = [];
      const frequencyData: number[] = [];
      const calibrationDuration = 5000; // 5 seconds
      const startTime = Date.now();

      const calibrationInterval = setInterval(() => {
        if (this.analyser && this.dataArray) {
          this.analyser.getByteFrequencyData(this.dataArray);
          const level = this.calculateLevel(this.dataArray);
          calibrationData.push(level);
          frequencyData.push(...Array.from(this.dataArray));
        }

        if (Date.now() - startTime >= calibrationDuration) {
          clearInterval(calibrationInterval);
          const profile = this.createSoundProfile(step, calibrationData, frequencyData);
          
          if (step === 'blow') {
            this.blowProfile.set(profile);
          } else {
            this.antiBreathProfiles.update(profiles => [...profiles, profile]);
          }

          this.log(`Calibrated ${step} profile:`, profile);
          this.stopAudioContext();
          resolve(profile);
        }
      }, 50); // Collect samples every 50ms
    });
  }

  private async initializeAudioContext() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.microphone = this.audioContext.createMediaStreamSource(stream);
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 2048;
    this.microphone.connect(this.analyser);
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
  }

  private stopAudioContext() {
    if (this.microphone) {
      this.microphone.disconnect();
    }
    if (this.analyser) {
      this.analyser.disconnect();
    }
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
    this.microphone = null;
    this.analyser = null;
    this.dataArray = null;
  }

  private createSoundProfile(type: 'blow' | 'laugh' | 'clap' | 'hello', calibrationData: number[], frequencyData: number[]): UnifiedSoundProfile {
    const profile: UnifiedSoundProfile = {
      id: 'temp-id', // This will be updated when saving to Firestore
      type: type,
      filename: 'temp-filename', // This will be updated when saving to Firestores
      createdAt: Timestamp.now(),
      createdBy: this.userService.getCurrentUserId() || 'unknown',
      deviceType: 'desktop', // Set to 'desktop' by default
      breathingPattern: calibrationData,
      averageLevel: calibrationData.reduce((a, b) => a + b, 0) / calibrationData.length,
      maxLevel: Math.max(...calibrationData),
      minLevel: Math.min(...calibrationData),
      threshold: Math.max(...calibrationData) * 0.5,
      duration: calibrationData.length * 50, // 50ms per sample
      frequencyProfile: this.calculateAverageFrequencyProfile(frequencyData),
      riseTime: this.calculateRiseTime(calibrationData),
      fallTime: this.calculateFallTime(calibrationData),
      backgroundNoiseLevel: Math.min(...calibrationData),
      consistencyScore: this.calculateConsistency(calibrationData),
      peakFrequency: this.calculatePeakFrequency(new Uint8Array(this.calculateAverageFrequencyProfile(frequencyData))),
    };

    return profile;
  }

  private calculateAverageLevel(data: number[]): number {
    return data.reduce((a, b) => a + b, 0) / data.length;
  }

  private calculateBackgroundNoiseLevel(data: number[]): number {
    const sortedData = [...data].sort((a, b) => a - b);
    return sortedData[Math.floor(sortedData.length * 0.1)]; // 10th percentile
  }

  private calculateThreshold(data: number[]): number {
    const sortedData = [...data].sort((a, b) => a - b);
    const percentile = 0.75; // 75th percentile
    const index = Math.floor(sortedData.length * percentile);
    return sortedData[index] * 1.5; // Adjust multiplier as needed
  }

  private calculateAverageFrequencyProfile(frequencyData: number[]): number[] {
    if (!this.analyser) {
      return [];
    }
    const profileLength = this.analyser.frequencyBinCount;
    const averageProfile = new Array(profileLength).fill(0);
    const numSamples = frequencyData.length / profileLength;
    
    for (let i = 0; i < frequencyData.length; i++) {
      const binIndex = i % profileLength;
      averageProfile[binIndex] += frequencyData[i] / numSamples;
    }
    
    return averageProfile;
  }

  async finalizeCalibration(type: 'blow' | 'laugh' | 'clap' | 'hello'): Promise<void> {
    const profilesRef = collection(this.firestore, 'config/soundSettings/soundProfiles');
    
    const profile = type === 'blow' ? this.blowProfile() : this.antiBreathProfiles().find(p => p.type === type);
    
    if (profile) {
      const docRef = await addDoc(profilesRef, profile);
      
      // Update the document with its ID and filename
      const filename = `profiles/${type}/${docRef.id}.webm`;
      await updateDoc(docRef, { 
        id: docRef.id,
        filename: filename
      });

      // Update the local profile
      if (type === 'blow') {
        this.blowProfile.update(p => {
          const updatedProfile = p ? { ...p, id: docRef.id, filename: filename } : p;
          this.log('Updated blow profile:', updatedProfile);
          return updatedProfile;
        });
      } else {
        this.antiBreathProfiles.update(profiles => 
          profiles.map(p => p.type === type ? { ...p, id: docRef.id, filename: filename } : p)
        );
      }
    }
  }

  private convertToNumberArray(data: Uint8Array | number[]): number[] {
    return Array.from(data);
  }

  private breathState = {
    isBreathing: false,
    breathDuration: 0,
    breathStartTime: 0
  };

  public async analyzeAndUpdateProfile(profile: UnifiedSoundProfile): Promise<UnifiedSoundProfile> {
    this.log(`Analyzing profile ${profile.id} with FFT...`);
    
    const analyzedProfile = this.analyzeProfileWithFFT(profile);
    
    if (analyzedProfile.fftMagnitudes && analyzedProfile.dominantFrequency) {
      this.log(`FFT analysis completed for profile ${profile.id}. Dominant frequency: ${analyzedProfile.dominantFrequency.toFixed(2)} Hz`);
      
      // Update the profile in Firestore with the FFT data
      const profileRef = doc(this.firestore, `config/soundSettings/soundProfiles/${profile.id}`);
      await updateDoc(profileRef, {
        fftMagnitudes: analyzedProfile.fftMagnitudes,
        dominantFrequency: analyzedProfile.dominantFrequency
      });
      
      this.log(`Profile ${profile.id} updated in Firestore with FFT data.`);
    } else {
      this.log(`Profile ${profile.id} does not have frequency data for FFT analysis.`);
    }

    return analyzedProfile;
  }

  setWebcamStarted(started: boolean) {
    this.isWebcamStarted = started;
    if (started) {
      this.performFFTAnalysis();
    } else {
      // Stop any ongoing audio analysis when webcam is stopped
      this.stopAudio();
    }
  }

  private async performFFTAnalysis() {
    if (!this.isWebcamStarted) {
      this.log('Webcam not started, skipping FFT analysis');
      return;
    }

    this.log('Starting FFT analysis of sound profiles...');
    
    // Perform FFT analysis on existing profiles
    const updatedProfiles = this.calibratedProfiles.map(profile => this.analyzeProfileWithFFT(profile));
    
    // Update the calibrated profiles with the new FFT data
    this.calibratedProfiles = updatedProfiles;

    // Update Firestore with the new FFT data
    for (const profile of updatedProfiles) {
      if (profile.fftMagnitudes && profile.dominantFrequency) {
        const profileRef = doc(this.firestore, `config/soundSettings/soundProfiles/${profile.id}`);
        await updateDoc(profileRef, {
          fftMagnitudes: profile.fftMagnitudes,
          dominantFrequency: profile.dominantFrequency
        });
      }
    }

    this.log('FFT analysis completed for all sound profiles');
  }

  async startAudioDetection(stream: MediaStream): Promise<void> {
    if (DEBUG_MODE) console.group('AudioDetectionService: startAudioDetection');
    this.log('Received stream for audio detection:', stream);
    
    if (!this.positiveProfiles.length && !this.negativeProfiles.length) {
      this.log('No profiles loaded. Attempting to load profiles...');
      await this.reloadSoundProfiles();
    }

    try {
      await this.initAudio(stream);
      this.log('Audio initialized successfully');
      this.logBlowProfileStatus();
      this.startAudioAnalysis();
      this.log('Audio analysis started');
    } catch (error) {
      this.log('Error starting audio detection:', error);
    } finally {
      if (DEBUG_MODE) console.groupEnd();
    }
  }

  private logBlowProfileStatus() {
    this.log('Current blow profile:', this.blowProfile());
    if (!this.blowProfile()) {
      this.log('No blow profile available. Attempting to load...');
      this.loadBlowProfile();
    }
  }

  private async loadBlowProfile() {
    try {
      const profile = await this.loadProfileFromStorage();
      if (profile) {
        this.blowProfile.set(profile);
        this.log('Blow profile loaded successfully:', profile);
      } else {
        this.log('Failed to load blow profile');
      }
    } catch (error) {
      this.log('Error loading blow profile:', error);
    }
  }

  private async loadProfileFromStorage(): Promise<UnifiedSoundProfile | null> {
    try {
      const profilesRef = collection(this.firestore, 'config/soundSettings/soundProfiles');
      const q = query(profilesRef, where('type', '==', 'blow'), limit(1));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const profileData = querySnapshot.docs[0].data() as UnifiedSoundProfile;
        this.log('Loaded blow profile from Firestore:', profileData);
        return profileData;
      } else {
        this.log('No blow profile found in Firestore');
        return null;
      }
    } catch (error) {
      this.log('Error loading blow profile from Firestore:', error);
      return null;
    }
  }

  getCurrentBlowProfile(): UnifiedSoundProfile | null {
    this.log('Current blow profile:', this.blowProfile());
    if (!this.blowProfile()) {
      this.log('No blow profile available');
    }
    return this.blowProfile();
  }
}