import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chart, ChartConfiguration, ChartType, ChartData, ScatterDataPoint } from 'chart.js';
import { UnifiedSoundProfile } from '@models/sound-profile.model';

@Component({
  selector: 'app-sound-profile-visualiser',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sound-profile-visualiser.component.html',
  styleUrls: ['./sound-profile-visualiser.component.css']
})
export class SoundProfileVisualiserComponent implements OnChanges, AfterViewInit {
  @Input() profiles: UnifiedSoundProfile[] = [];
  @ViewChild('chartCanvas') chartCanvasRef!: ElementRef;

  private chart: Chart | undefined;
  private chartsInitialized = false;
  activeTab: string = 'riseVsFall';

  ngAfterViewInit() {
    this.initializeChart();
    this.chartsInitialized = true;
    if (this.profiles.length > 0) {
      this.updateChart();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['profiles'] && this.profiles && this.chartsInitialized) {
      this.updateChart();
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.updateChart();
  }

  private initializeChart() {
    this.chart = this.createChart(this.chartCanvasRef.nativeElement, '');
  }

  private updateChart() {
    if (!this.chart) return;

    let data: ChartData<'scatter'>;
    let title: string;
    let xLabel: string;
    let yLabel: string;

    switch (this.activeTab) {
      case 'averageVsMax':
        data = this.prepareChartData(
          (p) => p.averageLevel,
          (p) => p.maxLevel,
          'Average Level',
          'Max Level'
        );
        title = 'Average Level vs Max Level';
        xLabel = 'Average Level';
        yLabel = 'Max Level';
        break;
      case 'riseVsFall':
        data = this.prepareChartData(
          (p) => p.riseTime || 0,
          (p) => p.fallTime || 0,
          'Rise Time (ms)',
          'Fall Time (ms)'
        );
        title = 'Rise Time vs Fall Time';
        xLabel = 'Rise Time (ms)';
        yLabel = 'Fall Time (ms)';
        break;
      case 'peakFreqVsConsistency':
        data = this.prepareChartData(
          (p) => p.peakFrequency || 0,
          (p) => p.consistencyScore || 0,
          'Peak Frequency (Hz)',
          'Consistency Score'
        );
        title = 'Peak Frequency vs Consistency Score';
        xLabel = 'Peak Frequency (Hz)';
        yLabel = 'Consistency Score';
        break;
      default:
        return;
    }

    this.updateChartData(this.chart, data, title, xLabel, yLabel);
  }

  private prepareChartData(
    xAccessor: (profile: UnifiedSoundProfile) => number,
    yAccessor: (profile: UnifiedSoundProfile) => number,
    xLabel: string,
    yLabel: string
  ): ChartData<'scatter'> {
    const datasets = [
      this.createDataset('Blow', 'rgba(255, 99, 132, 0.8)', (p) => p.type === 'blow'),
      this.createDataset('Laugh', 'rgba(54, 162, 235, 0.8)', (p) => p.type === 'laugh'),
      this.createDataset('Clap', 'rgba(255, 206, 86, 0.8)', (p) => p.type === 'clap'),
      this.createDataset('Hello', 'rgba(75, 192, 192, 0.8)', (p) => p.type === 'hello'),
    ];

    datasets.forEach(dataset => {
      dataset.data = this.profiles
        .filter(dataset.filter!)
        .map(p => ({ x: xAccessor(p), y: yAccessor(p) }));
    });

    // Ensure at least two datasets are returned
    while (datasets.length < 2) {
      datasets.push(this.createDataset('Empty', 'rgba(200, 200, 200, 0.8)', () => false));
    }

    return { datasets };
  }

  private createDataset(label: string, color: string, filter: (profile: UnifiedSoundProfile) => boolean) {
    return {
      label,
      backgroundColor: color,
      borderColor: color,
      pointRadius: 6,
      pointHoverRadius: 8,
      data: [] as ScatterDataPoint[],
      filter
    };
  }

  private createChart(canvas: HTMLCanvasElement, title: string): Chart {
    const ctx = canvas.getContext('2d')!;

    return new Chart(ctx, {
      type: 'scatter',
      data: { datasets: [] },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: title
          },
          legend: {
            position: 'top',
          },
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            titleFont: {
              weight: 'bold',
              size: 14
            },
            bodyFont: {
              size: 12
            },
            padding: 10,
            callbacks: {
              title: (tooltipItems) => {
                const item = tooltipItems[0];
                return item.dataset.label || '';
              },
              label: (context) => {
                const dataPoint = context.raw as ScatterDataPoint;
                const profile = this.profiles.find(p => 
                  p.type === context.dataset.label?.toLowerCase() && 
                  ((this.activeTab === 'averageVsMax' && p.averageLevel === dataPoint.x && p.maxLevel === dataPoint.y) ||
                   (this.activeTab === 'riseVsFall' && p.riseTime === dataPoint.x && p.fallTime === dataPoint.y) ||
                   (this.activeTab === 'peakFreqVsConsistency' && p.peakFrequency === dataPoint.x && p.consistencyScore === dataPoint.y))
                );
                
                if (profile) {
                  const date = profile.createdAt instanceof Date 
                    ? profile.createdAt 
                    : profile.createdAt.toDate();
                  const formattedDate = date.toLocaleString();
                  return [
                    `X: ${dataPoint.x}`,
                    `Y: ${dataPoint.y}`,
                    `ID: ${profile.id}`,
                    `Date: ${formattedDate}`
                  ];
                }
                return [
                  `X: ${dataPoint.x}`,
                  `Y: ${dataPoint.y}`
                ];
              }
            }
          }
        },
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: {
              display: true,
              text: ''
            }
          },
          y: {
            type: 'linear',
            position: 'left',
            title: {
              display: true,
              text: ''
            }
          }
        }
      }
    });
  }

  private updateChartData(chart: Chart, data: ChartData<'scatter'>, title: string, xLabel: string, yLabel: string) {
    chart.data = data;
    if (chart.options && chart.options.plugins && chart.options.plugins.title) {
      chart.options.plugins.title.text = title;
    }
    if (chart.options && chart.options.scales) {
      const xAxis = chart.options.scales['x'];
      const yAxis = chart.options.scales['y'];
      
      if (xAxis && 'title' in xAxis && xAxis.title) {
        xAxis.title.text = xLabel;
      }
      
      if (yAxis && 'title' in yAxis && yAxis.title) {
        yAxis.title.text = yLabel;
      }
    }
    chart.update();
  }
}