<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">

    <h2 class="text-2xl font-bold mb-6 text-secondary">Audio Settings</h2>

    <app-sound-profile-visualiser [profiles]="allProfiles()"></app-sound-profile-visualiser>

    <!-- Recording progress bar -->
    <div *ngIf="isRecording()"
        class="fixed top-0 left-0 right-0 z-50 p-4 bg-info text-info-content flex items-center justify-center">
        <div class="text-center mb-2">Recording in progress... ({{ currentRecordingType() }})</div>
        <progress class="progress progress-primary w-full" [value]="recordingProgress()" max="100"></progress>
    </div>

    <!-- Uploading status -->
    <div *ngIf="isUploading()"
        class="fixed top-0 left-0 right-0 z-50 p-4 bg-info text-info-content flex items-center justify-center">
        <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
        Uploading audio pattern...
    </div>

    <!-- Positive Breath Pattern Section -->
    <div class="card bg-base-100 lg:shadow-xl mb-8 px-1 lg:px-4">
        <div class="card-body px-1 lg:px-4">
            <h3 class="card-title text-lg font-semibold">Positive Blow Pattern Calibration:</h3>
            <p class="mb-4">Create a frequency domain profile of blow audio that will be used for FFT-based pattern
                matching.</p>

            <div class="grid grid-cols-1 gap-6 mb-8">
                <!-- Breath Pattern Card -->
                <div class="card bg-base-100">
                    <div class="card-body px-1 lg:px-4">
                        <h4 class="card-title text-base">Blow Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('blow')"
                            [disabled]="isRecording()">
                            💨 Record Blow
                            <span *ngIf="isRecording() && currentRecordingType() === 'blow'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of breathProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ profile.createdBy }}</td>
                                        <td>
                                            <select class="select select-bordered w-full max-w-xs" [(ngModel)]="profile.deviceType"
                                                (change)="updateDeviceType(profile)">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() !== null && isPlaying() !== profile.id">
                                                {{ isPlaying() === profile.id ? '⏹️ Stop' : '▶️ Play' }}
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Negative Pattern Calibration Section -->
    <div class="card bg-base-100 lg:shadow-xl mb-8 px-1 lg:px-4">
        <div class="card-body px-1 lg:px-4">
            <h3 class="card-title text-lg font-semibold">Negative Pattern Calibration:</h3>
            <p class="mb-4">Generate FFT profiles for non-breath audio to improve detection accuracy and reduce false
                positives.</p>

            <div class="grid grid-cols-1 gap-6">
                <!-- Laugh Pattern Card -->
                <div class="card">
                    <div class="card-body px-1">
                        <h4 class="card-title text-base">Laugh Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('laugh')"
                            [disabled]="isRecording() || laughProfiles().length >= 10">
                            😄 Record Laugh
                            <span *ngIf="isRecording() && currentRecordingType() === 'laugh'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of laughProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ profile.createdBy }}</td>
                                        <td>
                                            <select [(ngModel)]="profile.deviceType"
                                                (change)="updateDeviceType(profile)"
                                                class="select select-bordered w-full max-w-xs">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() !== null && isPlaying() !== profile.id">
                                                {{ isPlaying() === profile.id ? '⏹️ Stop' : 'Play' }}
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Clap Pattern Card -->
                <div class="card">
                    <div class="card-body px-1">
                        <h4 class="card-title text-base">Clap Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('clap')"
                            [disabled]="isRecording() || clapProfiles().length >= 10">
                            👏 Record Clap
                            <span *ngIf="isRecording() && currentRecordingType() === 'clap'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of clapProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ profile.createdBy }}</td>
                                        <td>
                                            <select class="select select-bordered w-full max-w-xs" [(ngModel)]="profile.deviceType"
                                                (change)="updateDeviceType(profile)">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() !== null && isPlaying() !== profile.id">
                                                {{ isPlaying() === profile.id ? '⏹️ Stop' : 'Play' }}
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Hello Pattern Card -->
                <div class="card">
                    <div class="card-body px-1">
                        <h4 class="card-title text-base">Hello Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('hello')"
                            [disabled]="isRecording() || helloProfiles().length >= 10">
                            👋 Record Hello
                            <span *ngIf="isRecording() && currentRecordingType() === 'hello'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of helloProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ profile.createdBy }}</td>
                                        <td>
                                            <select class="select select-bordered w-full max-w-xs" [(ngModel)]="profile.deviceType"
                                                (change)="updateDeviceType(profile)">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-ghost" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() !== null && isPlaying() !== profile.id">
                                                {{ isPlaying() === profile.id ? '⏹️ Stop' : 'Play' }}
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost" (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Add this at the end of your component template -->
    <dialog id="profile_details_modal" class="modal">
        <div class="modal-box">
            <h3 class="font-bold text-lg mb-4">Profile Details</h3>
            @if (selectedProfile) {
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <p><strong>ID:</strong> {{ selectedProfile.id }}</p>
                        <p><strong>Type:</strong> {{ selectedProfile.type }}</p>
                        <p class="break-all"><strong>Filename:</strong> {{ selectedProfile.filename }}</p>
                        <p><strong>Created At:</strong> {{ selectedProfile.createdAt | timestamp | date:'medium' }}</p>
                        <p><strong>Created By:</strong> {{ selectedProfile.createdBy }}</p>
                        <p><strong>Device Type:</strong> {{ selectedProfile.deviceType || 'Not specified' }}</p>
                        <p><strong>Average Level:</strong> {{ selectedProfile.averageLevel.toFixed(2) }}</p>
                        <p><strong>Max Level:</strong> {{ selectedProfile.maxLevel.toFixed(2) }}</p>
                        <p><strong>Min Level:</strong> {{ selectedProfile.minLevel.toFixed(2) }}</p>
                        <p><strong>Threshold:</strong> {{ selectedProfile.threshold.toFixed(2) }}</p>
                        <p><strong>Duration:</strong> {{ selectedProfile.duration.toFixed(2) }} seconds</p>
                    </div>
                    <div>
                        <p><strong>Rise Time:</strong> {{ selectedProfile.riseTime?.toFixed(2) || 'N/A' }} seconds</p>
                        <p><strong>Fall Time:</strong> {{ selectedProfile.fallTime?.toFixed(2) || 'N/A' }} seconds</p>
                        <p><strong>Background Noise Level:</strong> {{ selectedProfile.backgroundNoiseLevel?.toFixed(2) || 'N/A' }}</p>
                        <p><strong>Consistency Score:</strong> {{ selectedProfile.consistencyScore?.toFixed(2) || 'N/A' }}</p>
                        <p><strong>Peak Frequency:</strong> {{ selectedProfile.peakFrequency?.toFixed(2) || 'N/A' }} Hz</p>
                        <p><strong>Dominant Frequency:</strong> {{ selectedProfile.dominantFrequency?.toFixed(2) || 'N/A' }} Hz</p>
                    </div>
                </div>
                <div class="mt-4">
                    <h4 class="font-semibold mb-2">Breathing Pattern:</h4>
                    <div class="max-h-40 overflow-y-auto">
                        <p class="text-xs">{{ selectedProfile.breathingPattern.join(', ') }}</p>
                    </div>
                </div>
                @if (selectedProfile.frequencyProfile) {
                    <div class="mt-4">
                        <h4 class="font-semibold mb-2">Frequency Profile:</h4>
                        <div class="max-h-40 overflow-y-auto">
                            <p class="text-xs">{{ selectedProfile.frequencyProfile.join(', ') }}</p>
                        </div>
                    </div>
                }
                @if (selectedProfile.fftMagnitudes) {
                    <div class="mt-4">
                        <h4 class="font-semibold mb-2">FFT Magnitudes:</h4>
                        <div class="max-h-40 overflow-y-auto">
                            <p class="text-xs">{{ selectedProfile.fftMagnitudes.join(', ') }}</p>
                        </div>
                    </div>
                }
            }
            <div class="modal-action">
                <form method="dialog">
                    <button class="btn">Close</button>
                </form>
            </div>
        </div>
    </dialog>

</section>