import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoTrimService {
    async trimVideo(videoUrl: string | null, startTime: number, endTime: number): Promise<Blob> {
      return new Promise((resolve, reject) => {
        if (!videoUrl) {
          reject(new Error('No video URL provided'));
          return;
        }
  
        const video = document.createElement('video');
        video.src = videoUrl;
        video.crossOrigin = 'anonymous';
  
        video.onloadedmetadata = () => {
          video.currentTime = startTime;
        };

        video.onseeked = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          const chunks: Blob[] = [];
          const mediaRecorder = new MediaRecorder(canvas.captureStream(), { mimeType: 'video/webm' });

          mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
          };

          mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, { type: 'video/webm' });
            console.log('Trimmed video blob created, size:', blob.size);
            resolve(blob);
          };

          mediaRecorder.start();

          const captureFrame = () => {
            if (video.currentTime < endTime) {
              ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
              video.currentTime += 1 / 30; // Assume 30 fps
              requestAnimationFrame(captureFrame);
            } else {
              mediaRecorder.stop();
            }
          };

          captureFrame();
        };

        video.onerror = () => reject(new Error('Error loading video'));
      });
    }
  }