import '@angular/compiler';
import './polyfills';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import * as Sentry from "@sentry/angular";
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { isDevMode } from '@angular/core';  
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from './environments/environment';
import { importProvidersFrom } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

Sentry.init({
  dsn: "https://356ab791f2bb88feec57561cd35c9efe@o4507563751309312.ingest.us.sentry.io/4507563776540672",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

console.log('Main.ts: Starting application bootstrap');

// Add PWA installation checkss
const isPWAInstalled = window.matchMedia('(display-mode: standalone)').matches ||
                       window.matchMedia('(display-mode: fullscreen)').matches;
console.log(`Main.ts: PWA installation status: ${isPWAInstalled ? 'Installed' : 'Not installed'}`);

if (environment.erudaDebug) {
  const script = document.createElement('script');
  script.src = '//cdn.jsdelivr.net/npm/eruda';
  document.body.appendChild(script);
  script.onload = () => {
    (window as any).eruda.init();
  };
}

bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    ...(appConfig.providers || []),
    provideAnimations(),
    { provide: HttpClientModule, useClass: HttpClientModule },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    importProvidersFrom(MarkdownModule.forRoot()),
    importProvidersFrom(HttpClientModule)
  ]
})
  .then(() => {
    console.log('Main.ts: Application bootstrap completed successfully');
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/ngsw-worker.js').then(
        (registration) => console.log('Service Worker registered', registration),
        (err) => console.error('Service Worker registration failed', err)
      );
    }
  })
  .catch((err) => {
    console.error('Main.ts: Error during application bootstrap', err);
  });