<div class="flex flex-col h-full gap-8 relative ">



  <div class="top-0 left-0 right-0 bg-base-100 p-4 lg:px-12 shadow-lg z-10" *ngIf="!readOnly">
    <div class="flex justify-between items-center max-w-screen-xl mx-auto">
      <div class="flex gap-2 z-30">
        <button type="submit" class="btn btn-primary" [disabled]="!canSendVideo()" (click)="saveVideo()">
          Send
        </button>
        <div class="tooltip tooltip-bottom"
          [attr.data-tip]="!canSaveAsDraft() ? 'You must provide a Patient First name and Last name before you can save as draft' : null">
          <button type="button" class="btn btn-outline btn-secondary" (click)="saveAsDraft()"
            [disabled]="!canSaveAsDraft() || !isVideoSaved">
            Save as Draft
          </button>
        </div>
      </div>
      <div class="flex gap-2">
        <button *ngIf="video() && video().id" class="btn btn-error" (click)="confirmDelete()">
          Delete video record
        </button>
        <button class="btn btn-neutral" (click)="closeModal($event)">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
              fill="currentColor" stroke-width="0" />
          </svg>
          Close
        </button>
      </div>
    </div>
  </div>



  <div class="flex-grow overflow-y-auto flex flex-col gap-12  pb-24 p-4 lg:px-12">
    <!-- Display video details in read-only mode -->
    <div *ngIf="readOnly" class="w-full">
      <table class="table-auto w-full border-collapse border-1 border border-base-200 text-sm">
        <tbody class="video-details-table">
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Patient</td>
            <td class="border border-neutral p-2 text-xs">{{ video().patientFirstName }} {{ video().patientLastName }}
            </td>
          </tr>
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Patient age</td>
            <td class="border border-neutral p-2 text-xs">{{ video().patientDateOfBirth }}</td>
          </tr>
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Date of recording</td>
            <td class="border border-neutral p-2 text-xs">{{ video().createdAt | timestampToDate | date:'dd MMM yyyy
              \'at\' HH:mm' }}</td>
          </tr>
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Uploader</td>
            <td class="border border-neutral p-2 text-xs">{{ video().uploader || 'N/A' }}</td>
          </tr>
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Uploader occupation</td>
            <td class="border border-neutral p-2 text-xs">{{ video().uploaderOccupation || 'N/A' }}</td>
          </tr>
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Uploader email address</td>
            <td class="border border-neutral p-2 text-xs">{{ video().uploaderEmail || 'N/A' }}</td>
          </tr>
          <tr>
            <td class="border border-neutral p-2 font-semibold text-xs">Uploader organisation</td>
            <td class="border border-neutral p-2 text-xs">
              <div class="grid gap-1">
                <span>{{ video().uploaderOrganization || 'N/A' }}</span>
                <button class="btn btn-sm btn-outline" (click)="lookupOrganization(video().uploaderOrganization || '')"
                  [disabled]="!video().uploaderOrganization">Lookup</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Display video if there's a video source -->
    <div *ngIf="hasExistingVideo()" class="w-full mt-4 relative">
      <button *ngIf="!readOnly" class="btn btn-error btn-sm absolute top-2 right-2 z-10" (click)="deleteVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd" />
        </svg>
        Delete video
      </button>
      <!-- <video #videoElement [src]="videoSource() || ''" (loadedmetadata)="onVideoMetadataLoaded()"
        (timeupdate)="onVideoTimeUpdate()" controls class="w-full"></video> -->

      <app-videoedit [videoUrl]="videoSource() ?? ''" [videoDuration]="videoDuration()"
        [currentTime]="currentVideoTime()" [originalFilename]="video().filename || ''"
        (croppedVideoSaved)="onCroppedVideoSaved($event)" (seekTo)="seekVideo($event)">
      </app-videoedit>
    </div>


    <!-- Show recording button if there's no video -->
    <div *ngIf="!readOnly && !hasExistingVideo()" class="flex flex-col items-start gap-2 w-full">
      <div *ngIf="showDeleteSuccessAlert" role="alert" class="alert alert-success mb-2 w-full">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-current" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Video successfully deleted</span>
      </div>

      <div *ngIf="!canStartRecording()" role="alert" class="alert alert-info mb-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current shrink-0 w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
        <span>Please provide a patient First name and Last name before continuing</span>
      </div>
      <button [class]="recordingButtonClasses()" (click)="handleStartRecordingClick()"
        [disabled]="!canStartRecording() || isUploading()">
        {{ recordingButtonLabel() }}
      </button>
    </div>

    <!-- Update the recording modal -->
    <dialog #recordingModal class="modal modal-top rounded-none lg:rounded-lg">
      <div class="modal-box w-full h-full max-w-none sm:h-auto lg:h-100 p-0 lg:p-4">
        <div class="sticky top-50 sm:static bg-base-100 z-10 p-0 lg:p-4 shadow-md sm:shadow-none">
          <app-webcam [detectionMode]="true" [demoMode]="false" class="contents" (saveVideo)="onVideoSaved($event)"
            (recordingSaved)="onRecordingFinished($event)" (closeModal)="closeRecordingModal($event)"
            (discardRecording)="onRecordingDiscarded()" *ngIf="!readOnly">
          </app-webcam>
        </div>
      </div>
    </dialog>






    <form #videoForm="ngForm" class="pb-12">
      <div class="form-control">
        <label class="label" for="patientFirstName">
          <span class="label-text">Patient First Name</span>
        </label>
        <input id="patientFirstName" name="patientFirstName" type="text" [class.input-bordered]="!readOnly"
          class="input" [(ngModel)]="video().patientFirstName" (ngModelChange)="onFieldChange('patientFirstName')"
          [readonly]="readOnly" required>
      </div>
      <div class="form-control">
        <label class="label" for="patientLastName">
          <span class="label-text">Patient Last Name</span>
        </label>
        <input id="patientLastName" name="patientLastName" type="text" [class.input-bordered]="!readOnly" class="input"
          [(ngModel)]="video().patientLastName" (ngModelChange)="onFieldChange('patientLastName')" [readonly]="readOnly"
          required>
      </div>
      <div class="form-control">
        <label class="label" for="patientDateOfBirth">
          <span class="label-text">Patient Date of Birth</span>
        </label>
        <input id="patientDateOfBirth" name="patientDateOfBirth" type="date" [class.input-bordered]="!readOnly"
          class="input" [(ngModel)]="video().patientDateOfBirth" (ngModelChange)="onFieldChange('patientDateOfBirth')"
          [readonly]="readOnly">
      </div>
  

      <!-- Replace the existing form fields with this dynamic form -->
      <ng-container *ngIf="dynamicFields() as fields">
        <div *ngFor="let field of fields" class="form-control mt-4">
          <ng-container [ngSwitch]="field.type">
            <!-- Heading -->
            <ng-container *ngSwitchCase="'heading'">
              <h3 class="text-lg font-semibold mt-6 mb-2">{{ field.label }}</h3>
            </ng-container>

            <!-- Textarea input -->
            <ng-container *ngSwitchCase="'textarea'">
              <label class="label" [for]="field.id">
                <span class="label-text">{{ field.label }}</span>
                <span class="label-text-alt text-error" *ngIf="field.required">Required</span>
              </label>
              <textarea [id]="field.id" [name]="field.id" class="textarea textarea-bordered h-24"
                [(ngModel)]="video().additionalInfo[field.id]" (ngModelChange)="onFieldChange(field.id)"
                [readonly]="readOnly" [required]="field.required ?? false"></textarea>
            </ng-container>

            <!-- Rating inputs -->
            <ng-container *ngSwitchCase="'rating'">
              <label class="label">
                <span class="label-text">{{ field.label }}</span>
                <span class="label-text-alt text-error" *ngIf="field.required">Required</span>
              </label>
              <input type="range" [id]="field.id || ''" [name]="field.id || ''" min="0" max="100" step="25"
                class="range" [(ngModel)]="video().additionalInfo[field.id || '']"
                (ngModelChange)="onFieldChange(field.id || '')" [disabled]="readOnly"
                [required]="field.required ?? false" [value]="video().additionalInfo[field.id || ''] ?? 0">
              <div class="flex w-full justify-between px-2 text-xs mt-1">
                <span *ngFor="let option of field.options; let i = index"
                  [class.font-bold]="video().additionalInfo[field.id || ''] === i * 25">
                  {{ option }}
                </span>
              </div>
            </ng-container>


            <!-- Radio input -->
            <ng-container *ngSwitchCase="'radio'">
              <label class="label">
                <span class="label-text">{{ field.label }}</span>
                <span class="label-text-alt text-error" *ngIf="field.required">Required</span>
              </label>
              <div class="flex flex-wrap gap-4">
                <label *ngFor="let option of field.options" class="label cursor-pointer">
                  <input type="radio" [name]="field.id || ''" class="radio radio-primary" [value]="option"
                    [(ngModel)]="video().additionalInfo[field.id || '']" (ngModelChange)="onFieldChange(field.id || '')"
                    [disabled]="readOnly" [required]="field.required ?? false">
                  <span class="label-text ml-2">{{ option }}</span>
                </label>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <!-- Add this alert at the top of the component -->
    <div *ngIf="incompleteFields().length > 0"
      class="alert alert-warning shadow-lg mb-4 p-4 lg:px-12 shadow-lg rounded-none">

      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none"
        viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
      </svg>
      <div>
        <h3 class="font-bold">Please complete the following fields before sending:</h3>
        @for (field of incompleteFields(); track field) {
        <div class="text-xs">{{ field }}</div>
        }
      </div>

    </div>
  </div>

  <div class="fixed bottom-0 left-0 right-0 bg-base-100 p-4 shadow-lg z-10" *ngIf="!readOnly">
    <div class="flex justify-between items-center max-w-screen-xl mx-auto">
      <div class="flex gap-2">
        <button type="submit" class="btn btn-primary" [disabled]="!canSendVideo()" (click)="saveVideo()">
          Send
        </button>
        <div class="tooltip tooltip-top"
          [attr.data-tip]="!canSaveAsDraft() ? 'You must provide a Patient First name and Last name before you can save as draft' : null">
          <button type="button" class="btn btn-outline btn-secondary" (click)="saveAsDraft()"
            [disabled]="!canSaveAsDraft() || !isVideoSaved">
            Save as Draft
          </button>
        </div>
      </div>
      <div class="flex gap-2">
        <!-- Updated Delete button -->
        <!-- Replace the existing delete button with this -->
        <button class="btn btn-error btn-outline" (click)="confirmDelete()"
          [disabled]="!video() || !video().id || !video().videoUrl"
          [class.btn-disabled]="!video() || !video().id || !video().videoUrl">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          Delete
        </button>
        <!-- Close button -->
        <button class="btn btn-neutral" (click)="closeModal($event)">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
              fill="currentColor" stroke-width="0" />
          </svg>
          Close
        </button>
      </div>
    </div>
  </div>

  <!-- Delete confirmation modal -->
  <dialog #deleteConfirmModal class="modal modal-bottom sm:modal-middle">
    <div class="modal-box">
      <h3 class="font-bold text-lg">Confirm Delete</h3>
      <p class="py-4">Are you sure you want to delete this video? This action cannot be undone.</p>
      <div class="modal-action">
        <form method="dialog">
          <button class="btn btn-outline">Cancel</button>
        </form>
        <button class="btn btn-error" (click)="deleteVideo()">Delete</button>
      </div>
    </div>
  </dialog>
</div>