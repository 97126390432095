import { Component, OnInit, signal, computed, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Firestore, collection, getDocs, deleteDoc, doc, Timestamp, onSnapshot, query, where, updateDoc } from '@angular/fire/firestore';
import { Storage, ref, deleteObject } from '@angular/fire/storage';
import { VideoDetailsComponent } from '@shared/components/video-details/video-details.component';
import { Video, VideoStatus } from '@shared/models/video.model';
import { UserService } from '@shared/services/user.service';
import { User } from '@shared/models/user.model';
import { TimestampPipe } from '@shared/pipes/timestamp.pipe';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { DeleteRunwayService } from '@shared/services/delete-runway.service';
import { ToastService } from '@shared/services/toast.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-videos',
  standalone: true,
  imports: [CommonModule, VideoDetailsComponent, DatePipe, TimestampPipe, FormatDatePipe],
  templateUrl: './all-videos.component.html',
  styleUrls: ['./all-videos.component.css']
})
export class AllVideosComponent implements OnInit {
  private firestore: Firestore = inject(Firestore);
  private storage: Storage = inject(Storage);
  private userService = inject(UserService);
  private formatDatePipe = inject(FormatDatePipe);
  private deleteRunwayService = inject(DeleteRunwayService);
  private toastService = inject(ToastService);
  private route: ActivatedRoute = inject(ActivatedRoute);

  private videosSignal = signal<Video[]>([]);
  videos = computed(() => this.videosSignal());
  isViewVideoModalOpen = signal(false);
  editingVideo = signal<Video | null>(null);
  
  user = signal<User | null>(null);
  isSuperAdmin = computed(() => this.user()?.roles?.includes('superadmin') ?? false);
  currentTab = signal<VideoStatus>('submitted');
  
  filteredVideos = computed(() => 
    this.videos().filter(video => video.status === this.currentTab())
  );

  ngOnInit() {
    this.subscribeToVideos();
    this.userService.getUserState().subscribe(user => {
      this.user.set(user);
    });

    // Check for video ID in the route parameters
    this.route.queryParams.subscribe(params => {
      const videoId = params['videoId'];
      if (videoId) {
        this.openVideoById(videoId);
      }
    });
  }

  private subscribeToVideos() {
    const videosCollection = collection(this.firestore, 'videos');
    onSnapshot(videosCollection, (snapshot) => {
      const videosData = snapshot.docs.map(doc => this.convertToVideo(doc));
      this.videosSignal.set(videosData);
    });
  }

  private convertToVideo(doc: any): Video {
    const data = doc.data();
    const createdAt = this.ensureValidDate(data['createdAt']);
    const patientDateOfBirth = this.parseDate(data['patientDateOfBirth']);
    
    return {
      id: doc.id,
      userEmail: data['userEmail'] as string,
      patientFirstName: data['patientFirstName'] as string,
      patientLastName: data['patientLastName'] as string,
      patientDateOfBirth: patientDateOfBirth ? new Date(this.formatDatePipe.transform(patientDateOfBirth) || '') : null,
      description: data['description'] as string,
      status: data['status'] as VideoStatus,
      createdAt: createdAt,
      userId: data['userId'] as string,
      videoUrl: data['videoUrl'] as string | undefined,
      priorityScore: data['priorityScore'] as number,
      additionalInfo: data['additionalInfo'] || { symptoms: {}, conditions: {} }
    };
  }

  private ensureValidDate(value: any): Date | Timestamp {
    if (value instanceof Timestamp) {
      return value;
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Timestamp(value.seconds, value.nanoseconds);
    }
    return new Date(); // Default to current date if all else fails
  }

  private parseDate(value: any): Date | null {
    if (value instanceof Timestamp) {
      return value.toDate();
    }
    if (value instanceof Date) {
      return value;
    }
    if (typeof value === 'string') {
      const parsedDate = new Date(value);
      return isNaN(parsedDate.getTime()) ? null : parsedDate;
    }
    if (typeof value === 'object' && value && 'seconds' in value && 'nanoseconds' in value) {
      return new Date(value.seconds * 1000 + value.nanoseconds / 1000000);
    }
    return null;
  }

  viewVideo(video: Video) {
    console.log('Viewing video:', video);
    console.log('Video URL:', video.videoUrl);
    this.editingVideo.set(video);
    this.isViewVideoModalOpen.set(true);
  }

  onDrawerChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.openDrawer();
    } else {
      this.closeDrawer();
    }
  }

  openDrawer(): void {
    this.isViewVideoModalOpen.set(true);
  }

  closeDrawer(): void {
    this.isViewVideoModalOpen.set(false);
    this.editingVideo.set(null);
  }

  onVideoSaved() {
    // Implement video save logic if needed
    this.closeDrawer();
  }

  onVideoDraftSaved() {
    // Implement draft save logic if needed
    this.closeDrawer();
  }

  updateVideoStatus(video: Video, newStatus: VideoStatus) {
    const videoRef = doc(this.firestore, `videos/${video.id}`);
    updateDoc(videoRef, { status: newStatus })
      .then(() => {
        console.log('Video status updated successfully');
        // Update the local video object
        video.status = newStatus;
      })
      .catch((error: Error) => {
        console.error('Error updating video status:', error);
      });
  }

  isTabActive(tab: VideoStatus): boolean {
    return this.currentTab() === tab;
  }

  setTab(tab: VideoStatus): void {
    this.currentTab.set(tab);
  }

  getVideoCount(status: VideoStatus): number {
    return this.videos().filter(video => video.status === status).length;
  }

  async deleteVideo(video: Video) {
    if (confirm('Are you sure you want to delete this video?')) {
      try {
        if (video.videoUrl) {
          // Move the video file to the delete runway
          await this.deleteRunwayService.moveToDeleteRunway(video.videoUrl, 'recordings');
        }

        // Delete the Firestore document
        await this.deleteRunwayService.deleteFirestoreDocument('videos', video.id!);

        console.log('Video deleted successfully');
        this.toastService.show('Video moved to delete runway', 'success');
        
        // Update the local state
        this.videosSignal.update(videos => videos.filter(v => v.id !== video.id));
      } catch (error) {
        console.error('Error deleting video:', error);
        this.toastService.show('Error deleting video', 'error');
      }
    }
  }

  openVideoById(videoId: string) {
    const video = this.videos().find(v => v.id === videoId);
    if (video) {
      this.viewVideo(video);
    }
  }
}