import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { VideoTrimService } from '@services/video-trim.service';
import { RecordingService } from '@services/recording.service';

@Component({
  selector: 'app-videoedit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './videoedit.component.html'
})
export class VideoEditComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() videoDuration: number = 0;
  @Input() currentTime: number = 0;
  @Input() videoUrl: string | null = null;
  @Input() originalFilename: string = '';
  @Output() croppedVideoSaved = new EventEmitter<{start: number, end: number, url: string, duration: number}>();
  @Output() seekTo = new EventEmitter<number>();
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;

  timeForm: FormGroup;
  isVideoLoaded: boolean = false;
  trimmedVideoUrl: string | null = null;
  showTrimmedAlert: boolean = false;
  trimmedDuration: number | null = null;

  // Slider Values
  startTime: number = 0;
  endTime: number = 0;

  // Dragging State
  dragging: 'start' | 'end' | null = null;
  sliderRect: DOMRect | null = null;

  // Play State
  isPlaying: boolean = false;

  // Green Ball Position
  greenBallPosition: number = 0;

  constructor(
    private fb: FormBuilder,
    private videoTrimService: VideoTrimService,
    private cdr: ChangeDetectorRef,
    private recordingService: RecordingService
  ) {
    this.timeForm = this.fb.group({
      startTime: ['00:00', [Validators.required, Validators.pattern(/^([0-5][0-9]):([0-5][0-9])$/)]],
      endTime: ['00:00', [Validators.required, Validators.pattern(/^([0-5][0-9]):([0-5][0-9])$/)]]
    });
  }

  ngOnInit() {
    if (this.videoUrl) {
      this.processVideo();
    }
    this.endTime = this.videoDuration;
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    });
  }

  ngAfterViewInit() {
    this.checkVideoLoaded();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.group('VideoEditComponent: ngOnChanges');
    console.log('Changes:', changes);

    if (changes['videoDuration']) {
      console.log('Video duration changed:', this.videoDuration);
      this.updateEndTime();
    }
    if (changes['videoUrl']) {
      console.log('Video URL changed:', this.videoUrl);
      if (this.videoUrl) {
        this.processVideo();
      }
    }
    this.logVideoState();
    console.groupEnd();
  }

  
  setupVideoElement() {
    console.group('VideoEditComponent: setupVideoElement');
    if (this.videoElement && this.videoElement.nativeElement) {
      const video = this.videoElement.nativeElement;
      console.log('Video Element Ready State:', video.readyState);
      console.log('Video Element Source:', video.src);
      console.log('Video Element Duration:', video.duration);
      video.addEventListener('timeupdate', this.onTimeUpdate.bind(this));

      if (video.readyState >= 2) {
        this.onVideoLoaded();
      } else {
        video.onloadeddata = () => this.onVideoLoaded();
      }
    } else {
      console.log('Video Element: Not Available');
    }
    console.groupEnd();
  }

  private processVideo() {
    console.group('VideoEditComponent: processVideo');
    if (!this.videoUrl) {
      console.warn('No video URL provided');
      this.isVideoLoaded = false;
      this.cdr.detectChanges();
      console.groupEnd();
      return;
    }

    this.isVideoLoaded = false;
    this.cdr.detectChanges();

    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.src = this.videoUrl;
      this.setupVideoElement();
    }
    console.groupEnd();
  }

  private checkVideoLoaded() {
    console.group('VideoEditComponent: checkVideoLoaded');
    if (this.videoElement && this.videoElement.nativeElement) {
      const video = this.videoElement.nativeElement;
      console.log('Video element readyState:', video.readyState);
      console.log('Video element src:', video.src);
      
      if (video.readyState >= 2) {
        this.onVideoLoaded();
      } else {
        video.onloadeddata = () => this.onVideoLoaded();
      }
    } else {
      console.warn('Video element not found');
    }
    console.groupEnd();
  }

  private onVideoLoaded() {
    console.group('VideoEditComponent: onVideoLoaded');
    console.log('Video loaded');
    this.isVideoLoaded = true;
    this.cdr.detectChanges();
    this.logVideoState();
    console.groupEnd();
  }

  onVideoMetadataLoaded(event: Event): void {
    const video = event.target as HTMLVideoElement;
    if (video) {
      this.videoDuration = video.duration;
      console.log('Video metadata loaded. Duration:', this.videoDuration);
      this.updateEndTime();
      this.cdr.detectChanges();
    }
  }

  private updateEndTime() {
    this.endTime = this.videoDuration;
    this.timeForm.patchValue({
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
    this.updateGreenBall();
    this.cdr.detectChanges();
  }

  togglePlayPause() {
    if (!this.isVideoLoaded || !this.videoElement) return;

    const video = this.videoElement.nativeElement;
    if (this.isPlaying) {
      video.pause();
      this.isPlaying = false;
    } else {
      // Ensure the video starts within the trim range
      if (video.currentTime < this.startTime || video.currentTime > this.endTime) {
        video.currentTime = this.startTime;
      }
      video.play();
      this.isPlaying = true;
      this.startGreenBallMovement();
    }
    this.cdr.detectChanges();
  }

  // Trim Video Functionality
  trimVideo() {
    if (!this.videoUrl || !this.isVideoLoaded) {
      console.warn('Cannot trim video. Video URL is missing or video is not loaded.');
      return;
    }

    this.videoTrimService.trimVideo(this.videoUrl, this.startTime, this.endTime)
      .then(blob => {
        // Revoke the previous trimmed URL if it exists
        if (this.trimmedVideoUrl) {
          URL.revokeObjectURL(this.trimmedVideoUrl);
        }
        const trimmedUrl = URL.createObjectURL(blob);
        this.trimmedVideoUrl = trimmedUrl;
        this.trimmedDuration = (this.endTime - this.startTime);
        this.showTrimmedAlert = true;

        this.croppedVideoSaved.emit({
          start: this.startTime,
          end: this.endTime,
          url: trimmedUrl,
          duration: this.trimmedDuration
        });

        this.cdr.detectChanges();
      })
      .catch(error => {
        console.error('Error trimming video:', error);
      });
  }

  onTimeUpdate(event: Event): void {
    const video = event.target as HTMLVideoElement;
    this.currentTime = video.currentTime;
  
    if (video.currentTime >= this.endTime) {
      video.pause();
      video.currentTime = this.startTime;
      this.seekTo.emit(this.startTime);
      this.isPlaying = false;
      this.updateGreenBall();
      this.cdr.detectChanges();
    } else {
      this.updateGreenBall();
    }
  
    if (this.isPlaying) {
      this.updateGreenBall();
    }
  }

  onSliderChange(type: 'start' | 'end', value: number) {
    if (type === 'start') {
      this.startTime = Math.min(value, this.endTime);
    } else {
      this.endTime = Math.max(value, this.startTime);
    }
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
    this.updateGreenBall();
    this.cdr.detectChanges();
  }

  

  // Green Ball Movement
  startGreenBallMovement() {
    this.updateGreenBall();
  }

  updateGreenBall() {
    if (!this.isVideoLoaded) {
      this.greenBallPosition = 0;
      return;
    }

    const video = this.videoElement.nativeElement;
    const currentTime = video.currentTime;

    if (currentTime < this.startTime) {
      this.greenBallPosition = (0 / this.videoDuration) * 100;
      return;
    }

    if (currentTime > this.endTime) {
      this.greenBallPosition = (this.endTime / this.videoDuration) * 100;
      return;
    }

    this.greenBallPosition = (currentTime / this.videoDuration) * 100;
  }

  seekToPosition() {
    const video = this.videoElement.nativeElement;
    const newTime = (this.greenBallPosition / 100) * this.videoDuration;
    video.currentTime = newTime;
    this.seekTo.emit(newTime);
    this.updateGreenBall();
    this.cdr.detectChanges();
  }

  onTimeInputBlur(control: 'startTime' | 'endTime') {
    const value = this.timeForm.get(control)?.value;
    const timeInSeconds = this.parseTime(value);

    if (control === 'startTime') {
      this.startTime = Math.min(timeInSeconds, this.endTime);
    } else {
      this.endTime = Math.max(timeInSeconds, this.startTime);
    }

    this.updateGreenBall();
    this.cdr.detectChanges();
  }

  onKeyDown(event: KeyboardEvent, control: 'startTime' | 'endTime') {
    if (event.key === 'Enter') {
      this.onTimeInputBlur(control);
    }
  }

  startDragging(type: 'start' | 'end', event: MouseEvent) {
    event.preventDefault();
    this.dragging = type;
    this.sliderRect = (event.target as HTMLElement).parentElement?.getBoundingClientRect() || null;

    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDragging);
  }

  onDrag = (event: MouseEvent) => {
    if (!this.dragging || !this.sliderRect) return;

    const mouseX = event.clientX - this.sliderRect.left;
    let ratio = mouseX / this.sliderRect.width;
    ratio = Math.max(0, Math.min(ratio, 1)); // Clamp between 0 and 1
    const newTime = Math.round(ratio * this.videoDuration);

    if (this.dragging === 'start') {
      this.startTime = Math.min(newTime, this.endTime);
      // Seek video to new startTime
      if (this.isVideoLoaded) {
        this.videoElement.nativeElement.currentTime = this.startTime;
      }
    } else if (this.dragging === 'end') {
      this.endTime = Math.max(newTime, this.startTime);
    }

    this.timeForm.patchValue({ 
      startTime: this.formatTime(this.startTime), 
      endTime: this.formatTime(this.endTime) 
    }, { emitEvent: false });

    this.seekTo.emit(this.dragging === 'start' ? this.startTime : this.endTime);
    this.updateGreenBall();
    this.cdr.detectChanges();
  }

  stopDragging = () => {
    this.dragging = null;
    this.sliderRect = null;
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDragging);
  }

  formatTime(seconds: number): string {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${this.pad(mins)}:${this.pad(secs)}`;
  }

  parseTime(time: string): number {
    const parts = time.split(':').map(part => parseInt(part, 10));
    if (parts.length !== 2 || parts.some(isNaN)) {
      return 0;
    }
    return parts[0] * 60 + parts[1];
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  private logVideoState() {
    console.group('VideoEditComponent: Video State');
    console.log('Start Time:', this.startTime);
    console.log('End Time:', this.endTime);
    console.log('Is Video Loaded:', this.isVideoLoaded);
    console.log('Is Playing:', this.isPlaying);
    console.log('Green Ball Position:', this.greenBallPosition);
    if (this.videoElement && this.videoElement.nativeElement) {
      const video = this.videoElement.nativeElement;
      console.log('Video Element Ready State:', video.readyState);
      console.log('Video Element Source:', video.src);
      console.log('Video Element Duration:', video.duration);
    } else {
      console.log('Video Element: Not Available');
    }
    console.groupEnd();
  }
}