import { Injectable } from '@angular/core';
import { RecordingTimerService } from './recording-timer.service';
import { ResizeService } from './resize.service';
import { take, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanvasDrawService {
  private animationFrameId: number | null = null;

  constructor(
    private recordingTimerService: RecordingTimerService,
    private resizeService: ResizeService
  ) {}

  drawToCanvas(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    drawDimensions: { drawWidth: number; drawHeight: number; xOffset: number; yOffset: number },
    detectionMode: boolean,
    formatTime: (seconds: number) => string,
    cameraVisible: boolean,
    remainingTime: number
  ): void {
    this.resizeService.setupComplete$.pipe(
      filter(complete => complete),
      take(1)
    ).subscribe(() => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
  
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      if (cameraVisible && video.readyState === video.HAVE_ENOUGH_DATA) {
        // Draw video frame if camera is visible and video is ready
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      } else {
        // Draw black background when camera is off
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
  
      // Always draw the timer if in detection mode
      if (detectionMode) {
        const baseFontSize = 36;
        const scaleFactor = Math.min(canvas.width / 1280, canvas.height / 720); // Assuming 1280x720 as base resolution
        const fontSize = Math.max(Math.floor(baseFontSize * scaleFactor), 18); // Ensure minimum font size of 18px

        ctx.font = `${fontSize}px "Segoe UI", sans-serif`;
        const timerText = formatTime(remainingTime);
        
        // Calculate text width to center it horizontally
        const textMetrics = ctx.measureText(timerText);
        const textWidth = textMetrics.width;
        
        const timerX = canvas.width / 2 - textWidth / 2;
        const timerY = canvas.height - fontSize; // Position from bottom based on font size

        // Draw the timer text with a white glow effect
        ctx.shadowColor = 'rgba(255, 255, 255, 0.5)';
        ctx.shadowBlur = 10 * scaleFactor;
        ctx.fillStyle = 'white';
        ctx.fillText(timerText, timerX, timerY);

        // Reset shadow
        ctx.shadowColor = 'transparent';
        ctx.shadowBlur = 0;
      }
    });
  }

  startDrawLoop(drawFunction: () => void): void {
    const animate = () => {
      drawFunction();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    animate();
  }

  stopDrawLoop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }
}