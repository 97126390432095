import { Component, OnInit, OnDestroy, signal, inject, ChangeDetectorRef, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Firestore, collection, collectionData, addDoc, updateDoc, Timestamp } from '@angular/fire/firestore';
import { Storage, ref, uploadBytesResumable, getDownloadURL } from '@angular/fire/storage';
import { Observable, map, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UnifiedSoundProfile } from '@models/sound-profile.model';
import { SoundProfileVisualiserComponent } from '@shared/components/sound-profile-visualiser/sound-profile-visualiser.component';
import { SoundProfileService } from '@shared/services/sound-profile.service';
import { TimestampPipe } from '@shared/pipes/timestamp.pipe';
import { UserService } from '@services/user.service';
import { ToastService } from '@services/toast.service';
import { AudioDetectionService } from '@services/audio-detection.service';
import { Auth } from '@angular/fire/auth';
import { User } from '@models/user.model';
import { DeleteRunwayService } from '@services/delete-runway.service';

@Component({
  selector: 'app-audio-settings',
  templateUrl: './audio-settings.component.html',
  standalone: true,
  imports: [CommonModule, SoundProfileVisualiserComponent, FormsModule, TimestampPipe]
})
export class AudioSettingsComponent implements OnInit, OnDestroy {
  private firestore = inject(Firestore);
  private storage = inject(Storage);
  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private audioDetectionService = inject(AudioDetectionService);
  private auth = inject(Auth);
  private deleteRunwayService = inject(DeleteRunwayService);
  private cdr = inject(ChangeDetectorRef);
  private platformId = inject(PLATFORM_ID);
  private soundProfileService = inject(SoundProfileService);

  isRecording = signal(false);
  isUploading = signal(false);
  isPlaying = signal<string | null>(null);
  currentRecordingType = signal<'blow' | 'laugh' | 'clap' | 'hello' | null>(null);
  recordingProgress = signal(100);
  uploadProgress = signal(0);

  breathProfiles = signal<UnifiedSoundProfile[]>([]);
  laughProfiles = signal<UnifiedSoundProfile[]>([]);
  clapProfiles = signal<UnifiedSoundProfile[]>([]);
  helloProfiles = signal<UnifiedSoundProfile[]>([]);

  deviceTypes = ['desktop', 'mobile'];
  allProfiles = signal<UnifiedSoundProfile[]>([]);

  private readonly MAX_PROFILES_PER_TYPE = 10;
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private recordingInterval: any;
  private audioElement: HTMLAudioElement | null = null;
  private destroy$ = new Subject<void>();

  currentUser: User | null = null;
  lastRecordedProfile: UnifiedSoundProfile | null = null;
  selectedProfile: UnifiedSoundProfile | null = null;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadProfiles();
    }
    this.loadCurrentUser();
  }

  ngOnDestroy() {
    if (this.recordingInterval) {
      clearInterval(this.recordingInterval);
    }
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement = null;
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadProfiles() {
    const profilesRef = collection(this.firestore, 'config/soundSettings/soundProfiles');
    collectionData(profilesRef).pipe(
      map(profiles => profiles as UnifiedSoundProfile[]),
      takeUntil(this.destroy$)
    ).subscribe(
      (profiles: UnifiedSoundProfile[]) => {
        this.updateProfileSignals(profiles);
        this.allProfiles.set(profiles);
      },
      error => console.error('Error loading profiles:', error)
    );
  }

  private updateProfileSignals(profiles: UnifiedSoundProfile[]) {
    this.breathProfiles.set(profiles.filter(p => p.type === 'blow'));
    this.laughProfiles.set(profiles.filter(p => p.type === 'laugh'));
    this.clapProfiles.set(profiles.filter(p => p.type === 'clap'));
    this.helloProfiles.set(profiles.filter(p => p.type === 'hello'));
  }

  async startRecording(type: 'blow' | 'laugh' | 'clap' | 'hello') {
    if (this.isRecording() || this.getProfilesForType(type).length >= this.MAX_PROFILES_PER_TYPE) {
      return;
    }

    this.isRecording.set(true);
    this.currentRecordingType.set(type);
    this.recordingProgress.set(100);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.audioChunks = [];

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        this.isRecording.set(false);
        this.isUploading.set(true);
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        const profile = await this.audioDetectionService.calibrateStep(type);
        this.lastRecordedProfile = profile;
        await this.saveSoundProfile(type, audioBlob, profile);
        this.isUploading.set(false);
        stream.getTracks().forEach(track => track.stop());
      };

      this.mediaRecorder.start();

      this.recordingInterval = setInterval(() => {
        const currentProgress = this.recordingProgress();
        if (currentProgress > 0) {
          this.recordingProgress.set(currentProgress - 1);
        }
      }, 50);

      setTimeout(() => {
        if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
          this.mediaRecorder.stop();
          clearInterval(this.recordingInterval);
        }
      }, 5000);
    } catch (error) {
      console.error('Error starting recording:', error);
      this.isRecording.set(false);
      this.toastService.show('Error starting recording', 'error');
    }
  }

  async saveSoundProfile(type: 'blow' | 'laugh' | 'clap' | 'hello', audioBlob: Blob, profile: UnifiedSoundProfile) {
    const user = this.auth.currentUser;
    if (!user) {
      this.toastService.show('User not authenticated', 'error');
      return;
    }

    try {
      const userId = user.uid;
      const filename = `profiles/${type}/${userId}/${Date.now()}.webm`;

      const storageRef = ref(this.storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, audioBlob);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadProgress.set(progress);
        }
      );

      await uploadTask;

      const downloadURL = await getDownloadURL(storageRef);

      const profileData: Omit<UnifiedSoundProfile, 'id'> = {
        ...profile,
        filename: filename,
        createdAt: Timestamp.now(),
        createdBy: this.currentUser ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : 'Unknown User',
      };

      const docRef = await addDoc(collection(this.firestore, 'config/soundSettings/soundProfiles'), profileData);
      await updateDoc(docRef, { id: docRef.id });

      const savedProfile = { ...profileData, id: docRef.id } as UnifiedSoundProfile;
      const analyzedProfile = await this.audioDetectionService.analyzeAndUpdateProfile(savedProfile);
      console.log('Analyzed profile:', analyzedProfile);

      this.toastService.show('Sound profile saved and analyzed successfully', 'success');
      this.loadProfiles();
    } catch (error) {
      console.error('Error saving sound profile:', error);
      this.toastService.show('Error saving sound profile', 'error');
    } finally {
      this.isUploading.set(false);
      this.uploadProgress.set(0);
    }
  }

  async playAudio(profile: UnifiedSoundProfile) {
    if (this.isPlaying() === profile.id) {
      this.isPlaying.set(null);
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement = null;
      }
      return;
    }

    this.isPlaying.set(profile.id);
    try {
      const audioRef = ref(this.storage, profile.filename);
      const url = await getDownloadURL(audioRef);
      
      if (this.audioElement) {
        this.audioElement.pause();
      }
      
      this.audioElement = new Audio(url);
      this.audioElement.onended = () => {
        this.isPlaying.set(null);
        this.audioElement = null;
      };
      await this.audioElement.play();
    } catch (error) {
      console.error('Error playing audio:', error);
      this.toastService.show('Error playing audio', 'error');
      this.isPlaying.set(null);
    }
  }

  async updateDeviceType(profile: UnifiedSoundProfile) {
    try {
      if (profile.id && profile.deviceType) {
        await this.soundProfileService.updateDeviceType(profile.id, profile.deviceType);
        this.toastService.show('Device type updated successfully', 'success');
      } else {
        throw new Error('Invalid profile data');
      }
    } catch (error) {
      console.error('Error updating device type:', error);
      this.toastService.show('Error updating device type', 'error');
    }
  }
   
  

  async confirmDelete(profile: UnifiedSoundProfile) {
    if (confirm('Are you sure you want to delete this profile?')) {
      try {
        await this.deleteRunwayService.moveToDeleteRunway(profile.filename, 'audio-settings');
        await this.deleteRunwayService.deleteFirestoreDocument('config/soundSettings/soundProfiles', profile.id);
        this.toastService.show('Profile moved to delete runway', 'success');
        this.loadProfiles();
      } catch (error) {
        console.error('Error moving profile to delete runway:', error);
        this.toastService.show('Error moving profile to delete runway', 'error');
      }
    }
  }

  showProfileDetails(profile: UnifiedSoundProfile) {
    this.selectedProfile = profile;
    const modal = document.getElementById('profile_details_modal') as HTMLDialogElement;
    if (modal) {
      modal.showModal();
    }
  }

  trackByFn(index: number, item: UnifiedSoundProfile) {
    return item.id;
  }

  getAllProfiles(): UnifiedSoundProfile[] {
    return [
      ...this.breathProfiles(),
      ...this.laughProfiles(),
      ...this.clapProfiles(),
      ...this.helloProfiles()
    ];
  }

  async clearAllProfiles() {
    try {
      await this.soundProfileService.clearSoundProfiles();
      this.loadProfiles();
    } catch (error) {
      console.error('Error clearing profiles:', error);
      this.toastService.show('Error clearing profiles', 'error');
    }
  }

  private getProfilesForType(type: 'blow' | 'laugh' | 'clap' | 'hello'): UnifiedSoundProfile[] {
    switch (type) {
      case 'blow':
        return this.breathProfiles();
      case 'laugh':
        return this.laughProfiles();
      case 'clap':
        return this.clapProfiles();
      case 'hello':
        return this.helloProfiles();
    }
  }

  async loadCurrentUser() {
    this.currentUser = this.userService.getCurrentUser();
  }
}