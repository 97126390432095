import { Component, OnInit, OnDestroy, signal, computed, effect, CreateEffectOptions, inject, ElementRef, ViewChild, AfterViewInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioDetectionService } from '@services/audio-detection.service';

@Component({
  selector: 'app-pinwheel-overlay',
  templateUrl: './pinwheel-overlay.component.html',
  styleUrls: ['./pinwheel-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class PinwheelOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pinwheel') pinwheel!: ElementRef<HTMLDivElement>;

  private audioDetectionService = inject(AudioDetectionService);

  rotationSpeed = signal(10); // Default speed (10 seconds per rotation)
  private readonly FASTEST_ROTATION_TIME = 0.5; // Fastest speed (0.5 seconds per rotation)
  private readonly SLOWEST_ROTATION_TIME = 10; // Slowest speed (10 seconds per rotation)
  private readonly THRESHOLD_BUFFER = 0.001; // Small buffer above threshold
  private readonly DECELERATION_TIME = 5000; // 5 seconds in milliseconds
  private readonly BREATH_INTENSITY_MULTIPLIER = 10; // Increased for more noticeable effect
  private readonly DEBUG = true; // Debug flag

  rotationAngle = signal(0);
  private lastUpdateTime = 0;
  private animationFrameId: number | null = null;

  @Input() isWebcamActive = signal(false); // Provide initial value for signal

  private readonly BASE_SCALE = 1; // Base scale of the pinwheel
  private readonly MAX_SCALE_INCREASE = 0.2; // Maximum scale increase

  pulsateEffect = computed(() => {
    const speedRatio = 1 - (this.rotationSpeed() / this.SLOWEST_ROTATION_TIME);
    const pulsateIntensity = speedRatio * this.MAX_SCALE_INCREASE;
    const pulsateScale = this.BASE_SCALE + pulsateIntensity * Math.sin(Date.now() / 100);
    return pulsateScale;
  });

  shakeEffect = computed(() => {
    const maxShake = 1; // Reduced maximum shake
    const shakeIntensity = Math.max(0, (this.SLOWEST_ROTATION_TIME - this.rotationSpeed()) / this.SLOWEST_ROTATION_TIME);
    const shakeX = Math.sin(Date.now() / 50) * maxShake * shakeIntensity;
    const shakeY = Math.cos(Date.now() / 50) * maxShake * shakeIntensity;
    return `translate(${shakeX}px, ${shakeY}px)`;
  });

  pinwheelTransform = computed(() => {
    const baseScale = 1.2 + (this.SLOWEST_ROTATION_TIME - this.rotationSpeed()) / this.SLOWEST_ROTATION_TIME * 0.6;
    const breathIntensity = this.audioDetectionService.breathDetected() ? 
      Math.min(0.5, (this.audioDetectionService.audioLevel() - this.audioDetectionService.threshold()) / (this.audioDetectionService.threshold() * this.THRESHOLD_BUFFER)) : 0;
    const scale = baseScale + breathIntensity;
    return `rotate(${this.rotationAngle()}deg) scale(${scale})`;
  });

  private readonly DECELERATION_STEPS = 80; // Number of steps for deceleration
  private decelerationSpeeds: number[] = [];
  private decelerationIndex = 0;
  private decelerationInterval: number | null = null;

  constructor() {
    effect(() => {
      const breathDetected = this.audioDetectionService.breathDetected();
      this.updateRotationSpeed(breathDetected);
    }, { allowSignalWrites: true } as CreateEffectOptions);
  }

  ngOnInit() {
    console.log('PinwheelOverlayComponent initialized');
    this.startAnimation();
  }

  ngOnDestroy() {
    this.stopAnimation();
    this.clearDeceleration();
  }

  ngAfterViewInit() {
    if (this.pinwheel) {
      const pinwheelElement = this.pinwheel.nativeElement;
      // Add debug visuals if DEBUG is true
    } else {
      console.error('Pinwheel element not found');
    }
  }

  private updateRotationSpeed(breathDetected: boolean) {
    if (breathDetected) {
      // Clear any ongoing deceleration
      this.clearDeceleration();

      const audioLevel = this.audioDetectionService.audioLevel();
      const threshold = this.audioDetectionService.threshold();
      const acceleration = this.audioDetectionService.acceleration();

      const breathIntensity = Math.min(1, (audioLevel - threshold) / (threshold * this.THRESHOLD_BUFFER));
      
      const speedDecrease = Math.max(0.1, breathIntensity * this.BREATH_INTENSITY_MULTIPLIER * acceleration);
      const newSpeed = Math.max(this.FASTEST_ROTATION_TIME, this.rotationSpeed() - speedDecrease);
      this.rotationSpeed.set(newSpeed);
    } else if (this.rotationSpeed() < this.SLOWEST_ROTATION_TIME) {
      // Start deceleration if it's not already in progress
      if (this.decelerationSpeeds.length === 0) {
        this.startDeceleration();
      }
    }
  }

  private startDeceleration() {
    const startSpeed = this.rotationSpeed();
    const speedDifference = this.SLOWEST_ROTATION_TIME - startSpeed;
    
    // Create an array of speeds for gradual deceleration
    this.decelerationSpeeds = Array.from({ length: this.DECELERATION_STEPS }, (_, i) => {
      return startSpeed + (speedDifference * (i + 1) / this.DECELERATION_STEPS);
    });

    this.decelerationIndex = 0;
    
    // Set up an interval to update speed every 100ms (5000ms / 50 steps = 100ms per step)
    this.decelerationInterval = window.setInterval(() => {
      if (this.decelerationIndex < this.decelerationSpeeds.length) {
        this.rotationSpeed.set(this.decelerationSpeeds[this.decelerationIndex]);
        this.decelerationIndex++;
      } else {
        this.clearDeceleration();
      }
    }, 250);
  }

  private clearDeceleration() {
    if (this.decelerationInterval !== null) {
      clearInterval(this.decelerationInterval);
      this.decelerationInterval = null;
    }
    this.decelerationSpeeds = [];
    this.decelerationIndex = 0;
  }

  private startAnimation() {
    const animate = () => {
      const currentTime = Date.now();
      if (this.lastUpdateTime !== 0) {
        const deltaTime = (currentTime - this.lastUpdateTime) / 1000; // Convert to seconds
        const rotationSpeed = this.rotationSpeed();
        const angleChange = (360 / rotationSpeed) * deltaTime;
        
        // Update the rotation angle without constraining it to 0-360
        this.rotationAngle.update(angle => angle + angleChange);
      }
      this.lastUpdateTime = currentTime;
      this.animationFrameId = requestAnimationFrame(animate);
    };
    this.animationFrameId = requestAnimationFrame(animate);
  }

  private stopAnimation() {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  accelerate() {
    const currentSpeed = this.rotationSpeed();
    const newSpeed = Math.max(this.FASTEST_ROTATION_TIME, currentSpeed * 0.8);
    this.rotationSpeed.set(newSpeed);
    this.clearDeceleration();
  }

  decelerate() {
    if (this.decelerationInterval === null) {
      this.startDeceleration();
    }
  }
}