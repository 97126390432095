import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, inject, signal, computed, Input, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm, FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { WebcamComponent } from '@shared/components/webcam/webcam.component';
import { Firestore, collection, addDoc, doc, deleteDoc, getDoc, updateDoc, setDoc, DocumentReference, Timestamp, deleteField } from '@angular/fire/firestore';
import { Storage, ref, uploadBytes, getDownloadURL, deleteObject, getStorage } from '@angular/fire/storage';
import { UserService } from '@services/user.service';
import { Video, VideoStatus } from '@shared/models/video.model';
import { ToastService } from '@shared/services/toast.service';
import { HttpClientModule } from '@angular/common/http';
import { MailgunService } from '@shared/services/mailgun.service';
import { ModeOverlayComponent } from '@shared/components/overlays/mode-overlay/mode-overlay.component';
import { TimestampPipe } from '@shared/pipes/timestamp.pipe';
import { TimestampToDatePipe } from '@shared/pipes/timestamp-to-date.pipe';
import { firestore, storage } from '@shared/firebase-config';
import { VideoEditComponent } from '@shared/components/videoedit/videoedit.component';
import { StorageService } from '@shared/services/storage.service';
import { EmailService } from '@shared/services/email.service';
import { RecordingService } from '@services/recording.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData, docData } from 'rxfire/firestore';
import { DynamicField } from '@shared/models/dynamic-field.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteRunwayService } from '@shared/services/delete-runway.service';



@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    WebcamComponent,
    ModeOverlayComponent,
    TimestampToDatePipe,
    VideoEditComponent
  ],
  providers: [MailgunService]
})
export class VideoDetailsComponent implements OnInit, OnDestroy {
  @Output() closeModalEvent = new EventEmitter<void>();
  @Output() attemptCloseWithUnsavedChanges = new EventEmitter<boolean>();
  @ViewChild('videoForm') videoForm!: NgForm;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @Output() videoSaved = new EventEmitter<Video>();
  @Output() videoDraftSaved = new EventEmitter<void>();
  @Output() formDirtyChange = new EventEmitter<boolean>();
  @Output() draftSaved = new EventEmitter<string>();
  @Output() videoDeleted = new EventEmitter<string>();
  @Output() closeDrawer = new EventEmitter<void>();
  @Input() set editingVideo(value: Video | null) {
    console.log('Received editing video:', value);
    if (value) {
      this.video.set({...value});
      this.isVideoSaved = true;
      this.editingVideoId = value.id || null;
      if (value.videoUrl) {
        this.loadVideoAsBlob(value.videoUrl);
      }
    } else {
      this.resetForm();
    }
  }
  @ViewChild('recordingModal', { static: true }) recordingModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('videoEditModal') videoEditModal!: ElementRef;
  @ViewChild('deleteConfirmModal') deleteConfirmModal!: ElementRef;

  
  debugInfo = signal<string>('');
  formDirty = signal(false);
  isUploading = signal(false);
  uploadProgress = this.recordingService.getUploadProgress();
  uploadedFile = signal<{ filename: string, url: string } | null>(null);
  dynamicFields = signal<DynamicField[]>([]);

  video = signal<Video>({
    patientFirstName: '',
    patientLastName: '',
    patientDateOfBirth: null,
    description: '',
    status: 'draft',
    createdAt: new Date(),
    userId: '',
    priorityScore: 0,
    additionalInfo: {
      symptoms: {},
      conditions: {}
    }
  });
  
  editingVideoId: string | null = null;
  currentDateTime = signal(new Date().toLocaleString());
  savedVideoUrl = signal<string | null>(null);
  savedVideoFilename = signal<string | null>(null);
  private videoFilename: string | null = null;
  currentVideo: Video | null = null;
  isVideoSaved = false;
  localVideoUrl: string | null = null;
  showDeleteSuccessAlert = false;
  private _videoBlob: Blob | null = null;
  videoBlobUrl = signal<string | null>(null);
  private initialFormValue: any;
  webcamStarted = false;
  private isClosing = false;
   showTrimmedAlert: boolean = false;
  trimmedDuration: number = 0;

  

  @Input() readOnly: boolean = false;

  @ViewChild(WebcamComponent) webcamComponent!: WebcamComponent;

  videoSource = signal<string | null>(null);

  formValid = signal(false);
  incompleteFields = signal<string[]>([]);

  canSendVideo = computed(() => {
    const incompleteFields = this.updateIncompleteFields();
    const hasVideo = !!(this.video().videoUrl || this._videoBlob || (this.editingVideo && this.editingVideo.videoUrl));
    console.log('Can send video:', incompleteFields.length === 0 && hasVideo);
    return incompleteFields.length === 0 && hasVideo;
  });

  buttonLabel = computed(() => {
    return this.shouldShowSendButton() ? 'Send' : 'Update';
  });

  // Computed property to check if there's an existing video
  hasExistingVideo = computed(() => {
    const result = !!this.videoSource();
    console.log('hasExistingVideo:', result);
    return result;
  });

  // Update the recordingButtonLabel computed property
  recordingButtonLabel = computed(() => "START RECORDING");

  // Update the recordingButtonClasses computed property
  recordingButtonClasses = computed(() => {
    return this.hasExistingVideo() ? "btn btn-error" : "btn btn-primary";
  });

  private platformId = inject(PLATFORM_ID);

  videoDuration = signal(0);
  currentVideoTime = signal(0);
  private _videoUrl: string | null = null;

  get videoUrl(): string | null {
    return this._videoUrl || (this.editingVideo?.videoUrl ?? this.localVideoUrl);
  }

  set videoUrl(value: string | null) {
    this._videoUrl = value;
  }

  constructor(
    private firestore: Firestore,
    private storage: Storage,
    private userService: UserService,
    private toastService: ToastService,
    private mailgunService: MailgunService,
    private storageService: StorageService,
    private emailService: EmailService,
    private recordingService: RecordingService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private deleteRunwayService: DeleteRunwayService
  ) {    
    console.log('MailgunService instance:', this.mailgunService);
  }
  ngOnInit() {
    console.log('VideoDetailsComponent initialized');
    if (this.editingVideoId) {
      console.log(`Editing existing video with ID: ${this.editingVideoId}`);
    } else {
      console.log('Creating a new video');
    }
    this.initialFormValue = this.getFormValue();
    this.setupFormChangeDetection();

    this.recordingService.getRecordedBlob().subscribe(blob => {
      if (blob) {
        this.videoBlob = blob;
        this.updateVideoUrl(URL.createObjectURL(blob));
        this.isVideoSaved = true;
      }
    });

    this.loadDynamicFields();

    // Check if there's an existing video URL and load it
    if (this.video()?.videoUrl) {
      this.loadVideoAsBlob(this.video()?.videoUrl ?? '');
    }

    this.setupFormValidation();
    this.setupFormChangeListener();
    this.updateIncompleteFieldsSignal(); // Initial check

    this.route.queryParams.subscribe(params => {
      const videoId = params['videoId'];
      const state = params['state'];
      
      if (videoId) {
        // Load the video details
        this.loadVideoDetails(videoId);
      }
      
      if (state === 'readyToRecord') {
        // Trigger the recording modal to open
        this.openRecordingModal();
      }
    });

    if (this.editingVideo && this.editingVideo.videoUrl) {
      this.videoUrl = this.editingVideo.videoUrl;
    }

    this.updateVideoEditComponent();
  }

  private updateVideoEditComponent() {
    console.group('VideoDetailsComponent: updateVideoEditComponent');
    console.log('Video URL:', this.videoSource());
    console.log('Video Duration:', this.videoDuration());
    console.log('Current Time:', this.currentVideoTime());
    console.groupEnd();
  }

  loadVideoDetails(videoId: string) {
    // Implement the logic to load video details
    // This might involve a service call to fetch the video data
    // Update the component's state with the fetched data
  }



  private loadDynamicFields() {
    const formsCollection = collection(this.firestore, 'forms');
    const videoDetailsDoc = doc(formsCollection, 'videoDetails');
  
    docData(videoDetailsDoc).pipe(
      map(data => (data as any)?.fields || [])
    ).subscribe(fields => {
      this.dynamicFields.set(fields);
      this.updateFormControls(fields);
    });
  }
  
  private updateFormControls(fields: DynamicField[]) {
    if (this.videoForm) {
      fields.forEach(field => {
        if (field.required) {
          this.videoForm.form.addControl(field.id, new FormControl('', Validators.required));
        } else {
          this.videoForm.form.addControl(field.id, new FormControl(''));
        }
      });
    }
  }

  private updateVideoUrl(url: string) {
    this.video.update(currentVideo => ({
      ...currentVideo,
      videoUrl: url
    }));
  }

  private setupFormChangeDetection() {
    if (this.videoForm) {
      this.videoForm.valueChanges?.subscribe(() => {
        this.checkFormDirty();
      });

      // Add touch detection for each form control
      Object.keys(this.videoForm.form.controls).forEach(key => {
        const control = this.videoForm.form.get(key);
        control?.valueChanges.subscribe(() => {
          if (control.touched) {
            console.log(`Form field '${key}' touched. Form is now dirty.`);
            this.setFormDirty(true);
          }
        });
      });
    }
  }

  private setupFormValidation() {
    if (this.videoForm) {
      // Note: Ensure to import takeUntilDestroyed from '@angular/core/rxjs-interop'
      // at the top of the file:
      // import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
      this.videoForm.form.statusChanges.pipe(
        takeUntilDestroyed()
      ).subscribe((status) => {
        this.formValid.set(status === 'VALID');
      });
    }
  }
  private setupFormChangeListener() {
    if (this.videoForm?.valueChanges) {
      this.videoForm.valueChanges.pipe(
        takeUntilDestroyed()
      ).subscribe(() => {
        this.updateIncompleteFieldsSignal();
      });
    }
  }

  private updateIncompleteFields(): string[] {
    const incomplete: string[] = [];

    // Check static fields
    if (!this.video().patientFirstName?.trim()) incomplete.push('Patient First Name');
    if (!this.video().patientLastName?.trim()) incomplete.push('Patient Last Name');

    // Check dynamic fields
    this.dynamicFields().forEach(field => {
      if (field.required && !this.video().additionalInfo[field.id || '']) {
        incomplete.push(field.label);
      }
    });

    // Check if video is present
    if (!this.video().videoUrl && !this._videoBlob && !(this.editingVideo && this.editingVideo.videoUrl)) {
      incomplete.push('Video Recording');
    }

    console.log('Incomplete fields:', incomplete);
    return incomplete;
  }

  updateIncompleteFieldsSignal() {
    this.incompleteFields.set(this.updateIncompleteFields());
  }

  private checkFormDirty() {
    const currentValue = this.getFormValue();
    const isDirty = !this.isEqual(this.initialFormValue, currentValue);
    this.setFormDirty(isDirty);
  }

  private getFormValue(): any {
    return {
      patientFirstName: this.video().patientFirstName,
      patientLastName: this.video().patientLastName,
      patientDateOfBirth: this.video().patientDateOfBirth,
      description: this.video().description,
      priorityScore: this.video().priorityScore,
      additionalInfo: JSON.parse(JSON.stringify(this.video().additionalInfo))
    };
  }

  private isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  updateCurrentDateTime() {
    this.currentDateTime.set(new Date().toLocaleString());
  }


  async saveWithoutSending(): Promise<void> {
    if (this.formDirty()) {
      try {
        const user = this.userService.user();
        if (!user) {
          throw new Error('User not logged in');
        }

        const videoData: Partial<Video> = {
          id: this.editingVideoId || undefined, // Change 'temp_id' to undefined
          patientFirstName: this.video().patientFirstName || '',
          patientLastName: this.video().patientLastName || '',
          patientDateOfBirth: this.video().patientDateOfBirth || null,
          description: this.video().description || '',
          status: 'draft',
          userId: user.uid,
          priorityScore: this.video().priorityScore || 2.5,
          createdAt: new Date(),
          uploader: this.video().uploader || user.displayName || '',
          uploaderOccupation: this.video().uploaderOccupation || '',
          uploaderEmail: this.video().uploaderEmail || user.email || '',
          uploaderOrganization: this.video().uploaderOrganization || '',
          additionalInfo: this.video().additionalInfo || { symptoms: {}, conditions: {} }
        };

        const savedUrl = this.savedVideoUrl();
        if (savedUrl !== null) {
          videoData.videoUrl = savedUrl;
        }

        if (this.editingVideoId) {
          const videoRef = doc(this.firestore, 'videos', this.editingVideoId);
          const existingDoc = await getDoc(videoRef);
          if (existingDoc.exists()) {
            const existingData = existingDoc.data() as Video;
            videoData.createdAt = existingData.createdAt;
            await updateDoc(videoRef, videoData);
          } else {
            console.error('Video document not found');
          }
        } else {
          const docRef = await addDoc(collection(this.firestore, 'videos'), videoData);
          videoData.id = docRef.id;
        }
        
        this.draftSaved.emit('Draft saved successfully');
        this.videoDraftSaved.emit();
        this.closeModalEvent.emit();
      } catch (error) {
        console.error('Error saving video:', error);
        this.toastService.show('Error saving draft', 'error');
      }
    }
  }

  private async loadVideoAsBlob(url: string) {
    console.group('Loading video as blob');
    console.log('Firebase Storage URL:', url);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      console.log('Blob URL:', blobUrl);
      this.videoSource.set(blobUrl);
      this.cdr.detectChanges(); // Trigger change detection
    } catch (error) {
      console.error('Error loading video as blob:', error);
    }
    console.groupEnd();
  }
  


  
  private async uploadVideo(blob: Blob): Promise<string> {
    const filename = `video_${Date.now()}.webm`;
    const storageRef = ref(this.storage, `videos/${filename}`);
    await uploadBytes(storageRef, blob);
    return await getDownloadURL(storageRef);
  }

  onVideoSaved(videoData: { downloadURL: string; filename: string; }) {
    console.log('VideoDetailsComponent: Received video data', videoData);
    this.isVideoSaved = true;
    this.video.update(currentVideo => ({
      ...currentVideo,
      videoUrl: videoData.downloadURL,
      filename: videoData.filename
    }));
    console.log('Video saved in VideoDetailsComponent', this.video());
    this.closeRecordingModal();
    
    // Load the video as a blob
    this.loadVideoAsBlob(videoData.downloadURL);
    this.videoUrl = videoData.downloadURL;
  }

  closeRecordingModal(event?: string | Event): void {
    if (this.isClosing) {
      console.log('VideoDetailsComponent: Already closing, ignoring duplicate call');
      return;
    }

    this.isClosing = true;
    console.log('VideoDetailsComponent: Closing recording modal');

    if (this.webcamComponent) {
      this.webcamComponent.stopWebcam();
    }

    if (this.recordingModal?.nativeElement) {
      this.recordingModal.nativeElement.close();
    }

    this.recordingService.resetRecording();

    // Clear the video element
    if (this.videoElement?.nativeElement) {
      this.videoElement.nativeElement.src = '';
      this.videoElement.nativeElement.load();
    }

    // Clear videoBlob
    this.videoBlob = null;

    console.log('After clearing, videoBlob:', this._videoBlob, 'videoBlobUrl:', this.videoBlobUrl());
    console.trace('Trace for closeRecordingModal');

    this.isClosing = false;
    this.webcamStarted = false;
  }

  stopRecording(): void {
    if (this.webcamComponent) {
      this.webcamComponent.stopWebcam();
    }
  }

  confirmDelete(): void {
    if (this.video() && this.video().id) {
      this.deleteConfirmModal.nativeElement.showModal();
    } else if (confirm('Are you sure you want to delete this video? This action cannot be undone.')) {
      this.deleteVideo();
    } else {
      this.toastService.show('No video to delete', 'error');
    }
  }

  async deleteVideo(): Promise<void> {
    if (confirm('Are you sure you want to delete this video? This action cannot be undone.')) {
      const currentVideo = this.video();
      if (!currentVideo.id || !currentVideo.videoUrl) {
        console.error('Cannot delete video: missing id or videoUrl');
        return;
      }

      try {
        // First, update the Firestore document to remove the video URL
        const videoRef = doc(this.firestore, 'videos', currentVideo.id);
        await updateDoc(videoRef, {
          videoUrl: deleteField(),
          filename: deleteField(),
          duration: deleteField()
        });

        // Then, send the video file to the delete runway
        await this.deleteRunwayService.moveToDeleteRunway(currentVideo.videoUrl, currentVideo.id);

        // Update the local video object
        this.video.update(v => ({
          ...v,
          videoUrl: undefined,
          filename: undefined,
          duration: undefined
        }));

        this.toastService.show('Video deleted successfully', 'success');
        this.videoDeleted.emit(currentVideo.id);
        this.resetVideoProperties();

        this.clearAllUrlParams();
      } catch (error) {
        console.error('Error deleting video:', error);
        this.toastService.show('Error deleting video', 'error');
      }
    }
  }

  private resetVideoProperties(): void {
    this.video.update(currentVideo => ({
      ...currentVideo,
      videoUrl: undefined,
      filename: undefined,
      duration: null
    }));

    this.isVideoSaved = false;
    this.videoSource.set(null);
    if (this.videoBlobUrl()) {
      URL.revokeObjectURL(this.videoBlobUrl()!);
      this.videoBlobUrl.set(null);
    }
    this.videoBlob = null;

    // Reset the video element
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.src = '';
      this.videoElement.nativeElement.load();
    }

    // Reset video-related signals
    this.videoDuration.set(0);
    this.currentVideoTime.set(0);

    // Trigger change detection
    this.cdr.detectChanges();
  }

  private extractFileNameFromUrl(url: string): string | null {
    const match = url.match(/\/o\/(.+?)\?/);
    if (match && match[1]) {
      return decodeURIComponent(match[1]);
    }
    return null;
  }

  

  async saveAsDraft(): Promise<void> {
    const user = this.userService.user();
    if (!user) {
      console.error('User not logged in');
      this.toastService.show('User not logged in', 'error');
      return;
    }

    try {
      let videoData: Partial<Video> = {
        ...this.video(),
        status: 'draft' as VideoStatus,
        userId: user.uid,
        createdAt: Timestamp.now() // Use Firestore Timestamp
      };

      // If we have a new video blob, upload it
      if (this.videoBlob) {
        videoData.videoUrl = await this.uploadVideo(this.videoBlob);
      } else {
        // Use the saved URL if available, otherwise don't set videoUrl
        const savedUrl = this.savedVideoUrl();
        if (savedUrl !== null) {
          videoData.videoUrl = savedUrl;
        }
        // If savedUrl is null, videoUrl will remain undefined
      }

      // Explicitly remove videoUrl if it's null or undefined
      if (videoData.videoUrl === null || videoData.videoUrl === undefined) {
        delete videoData.videoUrl;
      }

      // Remove the id field if it's undefined
      if (videoData.id === undefined) {
        delete videoData.id;
      }

      // Clean up additionalInfo object
      if (videoData.additionalInfo) {
        videoData.additionalInfo = this.cleanObject(videoData.additionalInfo);
      }

      // Clean the entire videoData object
      videoData = this.cleanObject(videoData);

      console.log('Saving draft with data:', videoData);

      const docRef = await this.saveVideoMetadata(videoData);
      console.log('Video saved as draft with ID:', docRef.id);
      
      // Set form dirty state to false before showing toast and emitting event
      this.setFormDirty(false);
      
      this.toastService.show('Video saved as draft', 'success');
      this.videoDraftSaved.emit();

      // Update the local video object with the new ID
      this.video.update(v => ({ ...v, id: docRef.id }));

      // Remove URL parameters
      this.clearAllUrlParams();
    } catch (error) {
      console.error('Error saving video as draft:', error);
      this.toastService.show('Error saving video as draft', 'error');
    }
  }

  private cleanObject(obj: any): any {
    if (obj instanceof Date || obj instanceof Timestamp) {
      return obj;
    }
  
    const cleanedObj: any = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        cleanedObj[key] = this.cleanObject(obj[key]);
      } else if (obj[key] !== undefined) {
        cleanedObj[key] = obj[key];
      }
    });
    return cleanedObj;
  }

  private checkVideoContent() {
    setTimeout(() => {
      const videoElement = this.videoElement?.nativeElement;
      const sourceElement = videoElement?.querySelector('source');
      const sourceUrl = sourceElement?.src || videoElement?.src || '';

      let debugMessage = '';

      if (sourceUrl) {
        debugMessage += `Video source found: ${sourceUrl}\n`;
        if (sourceUrl.startsWith('blob:')) {
          debugMessage += 'URL type: Local (Blob URL)\n';
        } else if (sourceUrl.includes('firebasestorage')) {
          debugMessage += 'URL type: Remote (Firebase Storage)\n';
        } else if (sourceUrl.startsWith('http://localhost')) {
          debugMessage += 'URL type: Local (Development Server)\n';
        } else {
          debugMessage += 'URL type: Unknown\n';
        }
      } else {
        debugMessage += 'No video source found\n';
      }

      debugMessage += `video().videoUrl: ${this.video().videoUrl || 'not set'}\n`;
      debugMessage += `localVideoUrl: ${this.localVideoUrl || 'not set'}\n`;

      console.log(debugMessage);
      this.debugInfo.set(debugMessage);
    });
  }

  private async saveVideoMetadata(videoData: Partial<Video>): Promise<DocumentReference> {
    const videosCollection = collection(this.firestore, 'videos');
    if (videoData.id) {
      const docRef = doc(videosCollection, videoData.id);
      await setDoc(docRef, videoData, { merge: true });
      return docRef;
    } else {
      return await addDoc(videosCollection, videoData);
    }
  }

  calculatePatientAge(dateOfBirth: Date | Timestamp | null | undefined): number | null {
    if (!dateOfBirth) {
      return null;
    }

    const dob = dateOfBirth instanceof Timestamp ? dateOfBirth.toDate() : dateOfBirth;
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(date));
  }

  lookupOrganization(organization: string) {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(organization)}`, '_blank');
  }

  canSaveWithoutSending(): boolean {
    return !!this.video().patientFirstName && !!this.video().patientLastName;
  }



  onRecordingFinished(event: any) {
    console.log('Recording finished:', event);
    // Handle the finished recording here
    // You might want to update the video object or perform other actions
  }

  resetForm(): void {
    console.log('Resetting form');
    this.video.set({
      patientFirstName: '',
      patientLastName: '',
      patientDateOfBirth: null,
      description: '',
      status: 'draft',
      createdAt: new Date(),
      userId: '',
      priorityScore: 2.5,
      additionalInfo: { symptoms: {}, conditions: {} },
      videoUrl: '',
      filename: undefined
    });
    this.isVideoSaved = false;
    this.savedVideoUrl.set(null);
    this.savedVideoFilename.set(null);
    this.editingVideoId = null;
    this.videoSource.set(null);
    if (this.videoBlobUrl()) {
      URL.revokeObjectURL(this.videoBlobUrl()!);
      this.videoBlobUrl.set(null);
    }
    this.setFormDirty(false);
  }

  closeModal(event?: Event): void {
    console.log('VideoDetailsComponent: closeModal called, form dirty:', this.formDirty());
    if (event) {
      event.preventDefault();
    }

    if (this.formDirty() || this.hasUnsavedLocalVideo()) {
      this.attemptCloseWithUnsavedChanges.emit(true);
    } else {
      this.resetFormAndClose();
    }
    this.clearAllUrlParams();
    this.closeDrawer.emit();
  }



  private hasUnsavedLocalVideo(): boolean {
    return !!this.localVideoUrl && !this.editingVideo?.videoUrl;
  }

  private resetFormAndClose(): void {
    // Reset the form to its initial state
    this.video.set(this.initialFormValue);
    this.formDirty.set(false);
    
    // Clear local video data
    if (this.localVideoUrl) {
      URL.revokeObjectURL(this.localVideoUrl);
      this.localVideoUrl = null;
    }
    this.videoBlob = null;
    this.isVideoSaved = false;

    // Reset form using existing method
    this.resetForm();

    // Close the drawer/modal
    this.closeModalEvent.emit();
  }

  openRecordingModal(): void {
    console.log('openRecordingModal called, editingVideo:', this.editingVideo);
    this.isVideoSaved = false;
    this.webcamStarted = true;
    
    if (this.canStartRecording()) {
      console.log('Opening recording modal');
      this.updateUrl('readyToRecord', true);
      
      // Start the webcam when the modal is opened
      setTimeout(() => {
        if (this.webcamComponent) {
          this.webcamComponent.initializeForRecording();
        } else {
          console.error('WebcamComponent not found');
        }
      }, 0);

      // Fallback for browsers that don't support showModal
      if (this.recordingModal?.nativeElement) {
        if (this.recordingModal.nativeElement.showModal) {
          this.recordingModal.nativeElement.showModal();
        } else {
          this.recordingModal.nativeElement.style.display = 'block';
        }
      }
    } else {
      console.warn('Cannot start recording - conditions not met');
    }
  }

  private updateUrl(param: string | null | undefined, isState: boolean = true) {
    const currentVideoId = this.route.snapshot.queryParams['videoId'];
    const queryParams: { [key: string]: string | null | undefined } = {};
    
    if (isState) {
      queryParams['videoId'] = currentVideoId;
      queryParams['state'] = param ?? undefined;
    } else {
      queryParams['videoId'] = param ?? undefined;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  private openModal(): void {
    if (isPlatformBrowser(this.platformId)) {
      const modal = this.recordingModal.nativeElement;
      if (modal.showModal) {
        modal.showModal();
      } else {
        // Fallback for browsers that don't support showModal (like Safari)
        modal.setAttribute('open', '');
        document.body.classList.add('modal-open');
      }
    }
  }

  onModeSelected(mode: string): void {
    // Implement the logic for mode selection if needed
    console.log('Mode selected:', mode);
  }

  onRecordingStarted(): void {
    // Implement the logic for when recording starts
    console.log('Recording started');
  }

  private stopWebcamStream() {
    setTimeout(() => {
      if (this.webcamComponent) {
        this.webcamComponent.stopWebcamStream();
      }
    }, 300);
  }



  openVideoEditModal() {    
    console.log('Local video URL:', this.localVideoUrl);
    console.log('Editing video URL:', this.editingVideo?.videoUrl);
    this.videoEditModal.nativeElement.showModal();
  }



  private getVideoDuration(blob: Blob): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(isFinite(video.duration) ? video.duration : 0);
      };

      video.onerror = (e) => {
        reject('Error loading video metadata');
      };

      video.src = window.URL.createObjectURL(blob);
    });
  }

  

  async onCroppedVideoSaved(event: { start: number; end: number; url: string; duration: number }) {
    console.log('onCroppedVideoSaved called with event:', event);
    try {
      const croppedBlob = await this.cropVideo(event.start, event.end, event.url, event.duration);
      this.showTrimmedAlert = true;
      this.trimmedDuration = event.duration;

      // Generate a new filename for the trimmed video
      const originalFilename = this.extractFileNameFromUrl(event.url) || 'trimmed_video';
      const newFilename = `trimmed_${originalFilename}`;
      console.log('New trimmed video filename:', newFilename);

      // Save the trimmed video using your existing service
      const result = await this.recordingService.saveEditedRecording(croppedBlob, newFilename);
      
      if (result.success && result.data) {
        console.log('Trimmed video saved successfully:', result.data);
        // Update the video object with the new URL and duration
        this.video.update(currentVideo => ({
          ...currentVideo,
          videoUrl: result.data?.downloadURL ?? currentVideo.videoUrl,
          duration: event.duration
        }));

        // Update the video element with the new trimmed video
        if (this.videoElement && this.videoElement.nativeElement) {
          this.videoElement.nativeElement.src = result.data.downloadURL;
          this.videoElement.nativeElement.load();
        }
      } else {
        console.error('Failed to save trimmed video', result);
        throw new Error('Failed to save trimmed video');
      }
    } catch (error) {
      console.error('Error handling cropped video:', error);
      // You might want to show an error message to the user here
      this.toastService.show('Error saving trimmed video', 'error');
    }
  }


  private async cropVideo(start: number, end: number, url: string, duration: number): Promise<Blob> {
    console.log('cropVideo called with:', { start, end, url, duration });
    
    if (!this.videoElement || !this.videoElement.nativeElement) {
      console.error('Video element is not available');
      throw new Error('Video element is not available');
    }

    const video = this.videoElement.nativeElement;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Unable to create canvas context');
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    video.currentTime = start;

    return new Promise((resolve) => {
      video.onseeked = () => {
        const chunks: Blob[] = [];
        const mediaRecorder = new MediaRecorder(canvas.captureStream());

        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'video/webm' });
          console.log('Cropped video blob created, size:', blob.size);
          resolve(blob);
        };

        mediaRecorder.start();

        const drawFrame = () => {
          if (video.currentTime < end) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            video.currentTime += 1/30; // Assume 30 fps
            requestAnimationFrame(drawFrame);
          } else {
            mediaRecorder.stop();
          }
        };

        drawFrame();
      };
    });
  }

  private async updateVideoInDatabase(video: Video) {
    const videosCollection = collection(this.firestore, 'videos');
    const videoDocRef = doc(videosCollection, video.id!);
    await updateDoc(videoDocRef, { 
      videoUrl: video.videoUrl,
      duration: video.duration
    });
  }

  hasVideoFile(): boolean {
    return !!(this.editingVideo?.videoUrl || this.localVideoUrl);
  }

  shouldShowSendButton(): boolean {
    return !this.editingVideo || this.editingVideo.status === 'draft';
  }
  
  

  canStartRecording(): boolean {
    return !!this.video().patientFirstName && 
           !!this.video().patientLastName;
  }

  canSaveAsDraft(): boolean {
    return this.canStartRecording();
  }

  onFieldChange(fieldName: string): void {
    if (!this.formDirty()) {
      console.log(`Form field '${fieldName}' changed. Form is now dirty.`);
      this.setFormDirty(true);
    }
    
    // Get the field configuration
    const field = this.dynamicFields().find(f => f.id === fieldName);
    
    // Get the new value
    let newValue = this.videoForm.form.get(fieldName)?.value;
    
    // If it's a range input and the value is 0, set it to null
    if (field?.type === 'rating' && newValue === 0) {
      newValue = null;
    }
    
    // Update the video object with the new value
    this.video.update(currentVideo => ({
      ...currentVideo,
      additionalInfo: {
        ...currentVideo.additionalInfo,
        [fieldName]: newValue
      }
    }));
    
    this.updateIncompleteFieldsSignal();
    this.cdr.detectChanges();
  }

  private setFormDirty(isDirty: boolean) {
    if (this.formDirty() !== isDirty) {
      this.formDirty.set(isDirty);
      console.log('VideoDetailsComponent: Form dirty state changed:', isDirty);
      this.formDirtyChange.emit(isDirty);
    }
  }

  ngOnDestroy() {
    if (this.localVideoUrl) {
      URL.revokeObjectURL(this.localVideoUrl);
    }    
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.formDirty()) {
      $event.returnValue = true;
    }
  }

  // Update this method to handle video deletion if necessary
  handleStartRecordingClick() {
    console.group('Start Recording Button Click');
    console.log('Button clicked');
    console.assert(this.canStartRecording(), 'canStartRecording() should be true');
    console.assert(!this.isUploading(), 'isUploading() should be false');
    console.groupEnd();

    if (this.canStartRecording() && !this.isUploading()) {
      this.openRecordingModal();
    } else {
      console.warn('Start Recording button conditions not met');
    }
  }



  // Getter for videoBlob
  get videoBlob(): Blob | null {
    return this._videoBlob;
  }

  // Setter for videoBlob
  set videoBlob(value: Blob | null) {
    this._videoBlob = value;
    // Update the videoBlobUrl when videoBlob changes
    if (value) {
      this.videoBlobUrl.set(URL.createObjectURL(value));
    } else {
      if (this.videoBlobUrl()) {
        URL.revokeObjectURL(this.videoBlobUrl()!);
      }
      this.videoBlobUrl.set(null);
    }
  }

  // Add this method to show the incomplete fields alert
  showIncompleteFieldsAlert() {
    const incomplete = this.incompleteFields();
    if (incomplete.length > 0) {
      const fields = incomplete.join(', ');
      this.toastService.show(`Please complete the following fields: ${fields}`, 'error');
    }
  }

  // Update the saveVideo method to check if all required fields are completed before sending
  async saveVideo() {
    console.log('saveVideo method called');
    
    // Force update of incomplete fields
    this.updateIncompleteFieldsSignal();
    
    if (!this.canSendVideo()) {
      console.log('Cannot send video: incomplete fields or missing video');
      this.toastService.show('Please complete all required fields and provide a video before sending', 'error');
      return; // Exit the method early
    }

    let videoToSave: Partial<Video> = {
      ...this.video(),
      status: 'submitted' as VideoStatus,
      additionalInfo: {
        ...this.video().additionalInfo,
        eventDuration: this.video().additionalInfo.eventDuration || undefined
      }
    };
    
    // Check for video URL in this order: savedVideoUrl, videoBlob, editingVideo, existing video URL
    let videoUrl: string | undefined;
    
    const savedUrl = this.savedVideoUrl();
    if (savedUrl) {
      videoUrl = savedUrl;
    } else if (this.videoBlob) {
      try {
        const videoDuration = await this.getVideoDuration(this.videoBlob);
        videoToSave.duration = Math.round(videoDuration);
        videoUrl = await this.uploadVideo(this.videoBlob);
      } catch (error) {
        console.error('Error processing video:', error);
        this.toastService.show('Error processing video', 'error');
        return;
      }
    } else if (this.editingVideo?.videoUrl) {
      videoUrl = this.editingVideo.videoUrl;
      videoToSave.duration = this.editingVideo.duration;
    } else if (this.video().videoUrl) {
      videoUrl = this.video().videoUrl;
    }

    if (!videoUrl) {
      console.error('No video URL available');
      this.toastService.show('No video data available', 'error');
      return;
    }

    videoToSave.videoUrl = videoUrl;

    // Handle duration if it's a string (e.g., from user input)
    if (typeof videoToSave.duration === 'string') {
      const parsedDuration = parseInt(videoToSave.duration, 10);
      videoToSave.duration = isNaN(parsedDuration) ? null : parsedDuration;
    } else if (videoToSave.duration === undefined) {
      videoToSave.duration = null;
    }

    // Clean the videoToSave object
    videoToSave = this.cleanObject(videoToSave);

    try {
      console.log('Attempting to save video', JSON.stringify(videoToSave, null, 2));
      let videoDocRef: DocumentReference;
      if (this.editingVideoId) {
        const videoDoc = doc(this.firestore, 'videos', this.editingVideoId);
        await updateDoc(videoDoc, videoToSave);
        videoDocRef = videoDoc;
      } else {
        videoDocRef = await addDoc(collection(this.firestore, 'videos'), videoToSave);
      }
      this.editingVideoId = videoDocRef.id;
      console.log('Video saved successfully');
      
      // Update the local video object
      const updatedVideo: Video = { 
        ...this.video(), 
        status: 'submitted' as VideoStatus, 
        id: this.editingVideoId,
        videoUrl: videoUrl // Ensure the videoUrl is set in the updated video object
      };
      this.video.set(updatedVideo);
      
      // Emit the updated video
      this.videoSaved.emit(updatedVideo);

      console.log('Calling sendNewVideoNotification');
      this.emailService.sendNewVideoNotification();
      
      this.toastService.show('Video saved successfully', 'success');
    } catch (error) {
      console.error('Error saving video:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      this.toastService.show('Error saving video', 'error');
    }

    this.stopWebcamStream();
    this.removeUrlParams();
    this.closeModalEvent.emit();
  }


  onRecordingDiscarded(): void {
    this.isVideoSaved = false;
    this.videoSource.set(null);
    // The modal will be closed by the closeModal event, so we don't need to call closeRecordingModal here
  }

  onVideoMetadataLoaded() {
    if (this.videoElement) {
      this.videoDuration.set(this.videoElement.nativeElement.duration);
      console.log('Video duration set:', this.videoDuration());
    }
  }

  onVideoTimeUpdate() {
    if (this.videoElement) {
      this.currentVideoTime.set(this.videoElement.nativeElement.currentTime);
      console.log('Current video time:', this.currentVideoTime());
    }
  }

  seekVideo(time: number) {
    if (this.videoElement) {
      this.videoElement.nativeElement.currentTime = time;
    }
  }






  private removeUrlParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  private clearAllUrlParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true
    });
  }

}