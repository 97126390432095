import { Component, Input, Output, EventEmitter, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleOverlayComponent } from '../bubble-overlay/bubble-overlay.component';
import { MusicOverlayComponent } from '../music-overlay/music-overlay.component';
import { PinwheelOverlayComponent } from '../pinwheel-overlay/pinwheel-overlay.component';

@Component({
  selector: 'app-mode-overlay',
  templateUrl: './mode-overlay.component.html',
  styleUrls: ['./mode-overlay.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    BubbleOverlayComponent,
    MusicOverlayComponent,
    PinwheelOverlayComponent
  ]
})
export class ModeOverlayComponent {
  @Input() isAnimating: boolean = false;
  @Input() audioLevel: number = 0;
  @Input() threshold: number = 0.05;
  @Input() acceleration: number = 1;
  @Input() availableEffects: Array<'Bubbles' | 'Pinwheel' | 'Music'> = ['Bubbles', 'Pinwheel', 'Music'];
  @Input() selectedMode: 'Bubbles' | 'Pinwheel' | 'Music' | null = 'Pinwheel';
  @Input() breathDetected: boolean = false;
  @Input() isWebcamActive!: typeof signal; // Corrected type usage

  @Output() modeSelected = new EventEmitter<'Bubbles' | 'Pinwheel' | 'Music'>();

  @ViewChild(PinwheelOverlayComponent) pinwheelOverlay!: PinwheelOverlayComponent;

  selectMode(mode: 'Bubbles' | 'Pinwheel' | 'Music') {
    this.selectedMode = mode;
    this.modeSelected.emit(mode);
  }

  getEffectEmoji(effect: string): string {
    switch (effect.toLowerCase()) {
      case 'bubbles': return '🫧';
      case 'pinwheel': return '🎡';
      case 'music': return '🎵';
      default: return '';
    }
  }

  accelerateAnimation() {
    if (this.selectedMode === 'Pinwheel') {
      this.pinwheelOverlay.accelerate();
    }
    // Add similar logic for other modes if needed
  }

  decelerateAnimation() {
    if (this.selectedMode === 'Pinwheel') {
      this.pinwheelOverlay.decelerate();
    }
    // Add similar logic for other modes if needed
  }
}