<!-- <div>
  <p>Video URL: {{ videoUrl }}</p>
  <p>Video Duration: {{ videoDuration }}</p>
  <p>Is Video Loaded: {{ isVideoLoaded }}</p>
</div> -->

<!-- Add this above the video element -->
<div *ngIf="showTrimmedAlert" class="alert alert-success mb-4">
  <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
  <span>Showing new {{ trimmedDuration?.toFixed(1) }} second trimmed video</span>
</div>

<!-- Video Player with Hidden Controls -->
<video #videoElement [src]="trimmedVideoUrl || videoUrl || ''" (loadedmetadata)="onVideoMetadataLoaded($event)"
  (timeupdate)="onTimeUpdate($event)" [controls]="false" class="w-full">
</video>

<!-- Custom Video Controls -->
<div class="flex items-center space-x-4 mt-4 text-center">
  <button (click)="togglePlayPause()" class="btn btn-primary mx-auto">
    <svg *ngIf="!isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M14.752 11.168l-5.197-3.11A1 1 0 008 9.86v4.278a1 1 0 001.555.832l5.197-3.11a1 1 0 000-1.664z" />
    </svg>
    <svg *ngIf="isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6" />
    </svg>
  </button>


</div>

<!-- Green Ball Indicator -->
<div class="relative mt-4">
  <!-- Slider Row -->
  <div class="flex flex-col mb-6">
    <div class="relative w-full">
      <!-- Slider Track -->
      <input type="range" min="0" [max]="videoDuration" step="1" [(ngModel)]="startTime"
        (ngModelChange)="onSliderChange('start', $event)"
        class="absolute inset-0 w-full h-2 bg-gray-300 opacity-50 cursor-pointer" style="pointer-events: none;" />
      <input type="range" min="0" [max]="videoDuration" step="1" [(ngModel)]="endTime"
        (ngModelChange)="onSliderChange('end', $event)"
        class="absolute inset-0 w-full h-2 bg-gray-300 opacity-50 cursor-pointer" style="pointer-events: none;" />
      <!-- Slider Range Highlight -->
      <div class="absolute top-0 h-2 bg-blue-500 opacity-50" [style.left.%]="(startTime / videoDuration) * 100"
        [style.width.%]="((endTime - startTime) / videoDuration) * 100"></div>
      <!-- Start Handle -->
      <button
        class="border-2 absolute top-0 transform -translate-y-1/2 border btn btn-neutral text-white px-2 py-3 cursor-pointer"
        [style.left.%]="(startTime / videoDuration) * 100" (mousedown)="startDragging('start', $event)"
        aria-label="Set Start Time">
        |
      </button>
      <!-- End Handle -->
      <button
        class="border-2 absolute top-0 transform -translate-y-1/2 border btn btn-default text-white px-2 py-3 cursor-pointer"
        [style.left.%]="(endTime / videoDuration) * 100" (mousedown)="startDragging('end', $event)"
        aria-label="Set End Time">
        |
      </button>
      <!-- Green Ball Indicator -->
      <div class="absolute top-0 transform -translate-y-1/2 bg-green-500 rounded-full w-4 h-4"
        [style.left.%]="greenBallPosition"></div>
    </div>
  </div>

  <!-- Time Input Fields -->
  <form [formGroup]="timeForm" class="flex space-x-4 items-end">
    <div class="flex flex-col">
      <label for="startTime" class="mb-1">Start Time</label>
      <input id="startTime" formControlName="startTime" (blur)="onTimeInputBlur('startTime')"
        (keydown)="onKeyDown($event, 'startTime')" class="input input-bordered w-full" />
    </div>
    <div class="flex flex-col">
      <label for="endTime" class="mb-1">End Time</label>
      <input id="endTime" formControlName="endTime" (blur)="onTimeInputBlur('endTime')"
        (keydown)="onKeyDown($event, 'endTime')" class="input input-bordered w-full" />
    </div>
    <!-- Trim Button -->
    <button (click)="trimVideo()" class="btn btn-secondary flex items-center space-x-2">
      <span>✂️</span>
      <span>Trim</span>
    </button>
  </form>
</div>