

<div class="container mx-auto px-0 lg:px-4 max-w-[1280px] flex flex-col webcam-container" 
     [class.webcam-on]="webcamStarted"
     [class.fullscreen-webcam]="webcamStarted"
     [class.detection-mode]="detectionMode"
     [class.demo-mode]="demoMode">
  <div *ngIf="webcamStarted" class="webcam-controls bg-base-200 border-2 border-neutral flex flex-wrap justify-between w-full sticky top-0 z-40 divide-x divide-solid divide-neutral">
    <details #motivatorDropdown class="dropdown z-30 btn flex items-center gap-2 rounded-0">
      <summary class="flex items-center justify-between gap-2 h-full w-full">
        <span>{{ getSelectedMotivatorWithEmoji() }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </summary>
      <ul class="menu dropdown-conten bg-base-200">
        <li *ngFor="let effect of availableEffects">
          <a (click)="selectMode(effect)" class="text-neutral-content hover:bg-neutral-focus">
            {{ getEffectEmoji(effect) }} {{ effect }} 
          </a>
        </li>
      </ul>
    </details>
    
    <div class="recording-controls control-buttons flex flex-grow" *ngIf="webcamStarted && detectionMode">
      <button *ngIf="!isRecording" class="btn text-white px-4 pe-6 min-w-[220px] flex items-center justify-center bg-red-700 hover:bg-red-900" (click)="startRecording()" data-test-id="start-recording-button">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-record mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="2" stroke="white" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <circle cx="14" cy="14" r="10" fill="currentColor" class="text-red-600" />
        </svg>
        START RECORDING
      </button>
      <button *ngIf="isRecording" class="btn btn-primary px-4 pe-6 min-w-[220px] flex items-center justify-center"
        (click)="stopRecording()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-record animate-pulse mr-1" width="28"
          height="28" viewBox="0 0 28 28" stroke-width="2" stroke="white" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <circle cx="14" cy="14" r="10" fill="currentColor" class="text-red-800" />
        </svg>
        STOP
      </button>
      <button *ngIf="isRecordingStopped()" class="btn btn-success px-4 pe-6 min-w-[220px] flex items-center justify-center" (click)="saveVideoAndReturn('keep')" [disabled]="isUploading || isPreparingForEditing">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none"/>
          <path d="M5 14l6 6l12 -12" />
        </svg>
        KEEP
      </button>
      <button *ngIf="isRecordingStopped()" class="btn btn-error px-4 pe-6 min-w-[220px] flex items-center justify-center" (click)="discardAndCloseModal()" [disabled]="isUploading || isPreparingForEditing">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash mr-1" width="28" height="28"
          viewBox="0 0 28 28" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h28v28H0z" fill="none" />
          <path d="M5 8.5l18 0" />
          <path d="M11.5 13l0 7" />
          <path d="M16.5 13l0 7" />
          <path d="M6 8.5l1.5 14a2.5 2.5 0 0 0 2.5 2.5h9a2.5 2.5 0 0 0 2.5 -2.5l1.5 -14" />
          <path d="M10.5 8.5v-3.5a1.5 1.5 0 0 1 1.5 -1.5h4a1.5 1.5 0 0 1 1.5 1.5v3.5" />
        </svg>
        DISCARD
      </button>
    </div>
    
    <label class="label cursor-pointer btn px-2 hover:btn-info rounded-0">
      <span class="label-text text-current hover:text-inherit transition-colors duration-200">Camera</span>
      <input type="checkbox" class="toggle" [checked]="cameraVisible" (change)="toggleCameraVisibility()">
    </label>

    <button class="flex items-center gap-2 btn btn-neutral z-30 rounded-0"
      *ngIf="detectionMode || !detectionMode" (click)="closeModalAction()">     
      <svg xmlns="http://www.w3.org/2000/svg" class="icon stroke-accent-content" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm-1.489 7.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
        fill="currentColor" stroke-width="0" />
    </svg>
      {{ detectionMode ? 'Close' : 'Stop' }}
    </button>

  </div>

  <!-- Webcam container -->
  <div #webcamContainer class="webcam-video-container relative flex justify-center items-center w-full overflow-hidden lg:rounded-md mt-2"
       [ngClass]="{'bg-black': !cameraVisible}"
       style="width: 100%;">
    <img *ngIf="!webcamStarted && !detectionMode" [src]="randomPosterUrl" class="placeholder-image blur-poster w-full">
    <button *ngIf="!webcamStarted && !detectionMode" (click)="startWebcam()" class="absolute btn btn-secondary"
      style="z-index: 5;">
      Launch Demo
    </button>
    <div *ngIf="!webcamStarted && !detectionMode"
      class="spinning-circle absolute opacity-1 transition-opacity duration-2000">
      <div class="pinwheel overflow-hidden"><span class="c-1"></span><span class="c-2"></span><span
          class="c-3"></span><span class="c-4"></span><span class="c-5"></span><span class="c-6"></span><span
          class="c-7"></span><span class="c-8"></span><span class="c-1-copy"></span></div>
    </div>
   
      <video #videoElement autoplay playsinline muted 
             [style.visibility]="webcamStarted && cameraVisible ? 'visible' : 'hidden'"
             class="absolute object-cover w-full h-full">
      </video>
      <canvas #canvasElement 
              [style.visibility]="webcamStarted ? 'visible' : 'hidden'"
              class="absolute left-0 top-0 w-full h-full object-cover">
      </canvas>
      
      <div class="max-w-[80%]  w-full h-full z-30">
        <app-mode-overlay *ngIf="selectedMode && webcamStarted" [selectedMode]="selectedMode" [isAnimating]="isAnimating"
          [audioLevel]="audioLevel() || 0" [threshold]="threshold() || 0" [acceleration]="acceleration() || 1"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[80%]   w-full h-full flex items-center justify-center">
        </app-mode-overlay>
      </div>
      
      <div *ngIf="isRecordingStopped() && lastFrame"
        class="absolute inset-0 flex items-center justify-center overflow-hidden">
        <img [src]="lastFrame" [style.aspectRatio]="lastFrameAspectRatio" class="max-w-full max-h-full object-contain"
          alt="Last frame">
      </div>
      <div *ngIf="showTriggerAbsenceCard"
        class="absolute w-full h-full top-0 left-0 flex items-center justify-center z-40">
        <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 card bg-white text-neutral-content w-96">
          <div class="card-body items-center text-center">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-alert-triangle text-warning mb-2" width="48" height="48"
              viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 9v2m0 4v.01" />
              <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
            </svg>
            <h2 class="card-title">Trigger Absence Detected</h2>
            <p>If a trigger absence is detected, we annotate and upload to our Paediatric Neurologist for analysis.</p>
            <div class="card-actions justify-end">
              <button class="btn btn-primary" (click)="closeTriggerAbsenceCard()">Close</button>
            </div>
          </div>
        </div>
      </div>
   

    <div *ngIf="debugMode" class="absolute inset-0 flex items-center justify-center bg-gray-800 text-white z-50">
      <p>Debug Mode - No Camera Access Required</p>
    </div>
  </div>

  <!-- Preparing for editing dialog -->
  <div *ngIf="isPreparingForEditing" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-base-100 p-6 rounded-lg shadow-lg text-center">
      <h2 class="text-2xl font-bold mb-4">Preparing for Editing</h2>
      <progress class="progress progress-primary w-56" [value]="preparingProgress" max="100"></progress>
      <p class="mt-2">{{ preparingProgress }}%</p>
    </div>
  </div>
</div>