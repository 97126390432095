import { Injectable, inject } from '@angular/core';
import { Storage, ref, uploadBytes, getDownloadURL, deleteObject } from '@angular/fire/storage';
import { Firestore, doc, deleteDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DeleteRunwayService {
  private storage = inject(Storage);
  private firestore = inject(Firestore);

  private DEBUG_MODE = false; // Set this to true to enable detailed logging

  private log(...args: any[]): void {
    if (this.DEBUG_MODE) {
      console.log('[DeleteRunwayService]', ...args);
    }
  }

  async moveToDeleteRunway(originalPath: string | undefined, videoId: string): Promise<boolean> {
    if (!originalPath) {
      this.log('No original path provided for moveToDeleteRunway');
      return false;
    }

    try {
      const originalRef = ref(this.storage, originalPath);
      const fileBlob = await (await fetch(await getDownloadURL(originalRef))).blob();

      const fileExtension = originalPath.split('.').pop();
      const newPath = `deleteRunway/recordings/${videoId}.${fileExtension}`;
      const newRef = ref(this.storage, newPath);
      await uploadBytes(newRef, fileBlob);

      await deleteObject(originalRef);

      this.log(`File moved: ${originalPath} -> ${newPath}`);
      return true;
    } catch (error) {
      this.log('Error moving file to delete runway:', error);
      return false;
    }
  }

  async deleteFirestoreDocument(collectionPath: string, documentId: string | undefined): Promise<boolean> {
    if (!documentId) {
      this.log('No document ID provided for deleteFirestoreDocument');
      return false;
    }

    try {
      const docRef = doc(this.firestore, collectionPath, documentId);
      await deleteDoc(docRef);
      this.log(`Firestore document deleted: ${collectionPath}/${documentId}`);
      return true;
    } catch (error) {
      this.log('Error deleting Firestore document:', error);
      return false;
    }
  }

  setDebugMode(enabled: boolean): void {
    this.DEBUG_MODE = enabled;
    this.log(`Debug mode ${enabled ? 'enabled' : 'disabled'}`);
  }
}