import { Component, OnInit, effect } from '@angular/core';
import { Firestore, collection, addDoc } from '@angular/fire/firestore';
import { getStorageInstance } from '@shared/firebase-config';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { WebcamComponent } from '@shared/components/webcam/webcam.component';
import { UserService } from '@services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [WebcamComponent]
})
export class HomeComponent implements OnInit {
  selectedFile: File | null = null;
  demoMode = false;
  debugMode: boolean = false;

  constructor(private firestore: Firestore, private userService: UserService, private route: ActivatedRoute) {
    effect(() => {
      console.log('Homepage current user roles:', this.userService.userRoles());
    });
  }

  ngOnInit() {
    // Intentionally trigger an error to test Sentry integration
    // this.triggerError();

    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
    });
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
    }
  }

  uploadFile() {
    if (!this.selectedFile) {
      console.error('No file selected for upload.');
      return;
    }

    const storage = getStorageInstance();
    const storageRef = ref(storage, `uploads/${this.selectedFile.name}`);

    uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
      console.log('Uploaded a blob or file!', snapshot);
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    }).catch((error) => {
      console.error('Error uploading file:', error);
    });
  }

  onDemoModeChange(isDemo: boolean) {
    this.demoMode = isDemo;
  }

  // private triggerError() {
  //   // Define a dummy function to avoid compilation errors
  //   const myUndefinedFunction = () => {
  //     throw new Error('This is a test error for Sentry integration.');
  //   };
  //   myUndefinedFunction();
  // }
}