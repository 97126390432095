import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  private resizingEnabled = true; // Ensure resizing is enabled
  private setupCompleteSubject = new BehaviorSubject<boolean>(false);
  setupComplete$ = this.setupCompleteSubject.asObservable();

  constructor() {
    console.log('ResizeService instantiated');
  }

  adjustWebcamContainerSize(container: HTMLElement): void {
    console.group('ResizeService: adjustWebcamContainerSize');

    if (!this.resizingEnabled) {
      console.log('Resizing is disabled. Skipping adjustment.');
      console.groupEnd();
      return;
    }

    // Set width to 100% for all viewport sizes
    container.style.width = '100%';
    console.log('Set container width to 100%');

    // Get the computed height in pixels
    const computedStyle = window.getComputedStyle(container);
    const computedHeightStr = computedStyle.height;
    const computedHeight = parseInt(computedHeightStr, 10);

    if (isNaN(computedHeight)) {
      console.error(`Failed to parse computed height: "${computedHeightStr}"`);
      console.groupEnd();
      return;
    }

    console.log(`Computed container height: ${computedHeight}px`);

    // Check if it's a large viewport (Tailwind lg breakpoint is 1024px)
    const isLargeViewport = window.innerWidth >= 1024;
    console.log(`Is large viewport (>=1024px): ${isLargeViewport}`);

    if (isLargeViewport) {
      // For large viewports, subtract 100px from the computed height
      const adjustedHeight = computedHeight - 100;
      console.log(`Adjusted height for large viewport: ${adjustedHeight}px`);
      container.style.height = `${adjustedHeight}px`;
    } else {
      // For smaller viewports, keep the original computed height
      container.style.height = `${computedHeight}px`;
      console.log(`Set height for small viewport: ${computedHeight}px`);
    }

    console.log(
      `Final container dimensions - Width: ${container.style.width}, Height: ${container.style.height}`
    );
    console.groupEnd();
  }

  adjustCanvasSize(
    canvasElement: HTMLCanvasElement,
    videoElement: HTMLVideoElement,
    containerElement: HTMLElement
  ): { drawWidth: number; drawHeight: number; xOffset: number; yOffset: number } {
    console.group('ResizeService: adjustCanvasSize');
    console.log('Resizing enabled:', this.resizingEnabled);
    if (!this.resizingEnabled) {
      console.log('Resizing skipped');
      console.groupEnd();
      return {
        drawWidth: videoElement.videoWidth,
        drawHeight: videoElement.videoHeight,
        xOffset: 0,
        yOffset: 0
      };
    }

    const containerWidth = containerElement.clientWidth;
    const containerHeight = containerElement.clientHeight;
    const videoAspect = videoElement.videoWidth / videoElement.videoHeight;
    const containerAspect = containerWidth / containerHeight;

    let drawWidth, drawHeight, xOffset, yOffset;

    if (videoAspect > containerAspect) {
      drawWidth = containerWidth;
      drawHeight = containerWidth / videoAspect;
      xOffset = 0;
      yOffset = (containerHeight - drawHeight) / 2;
    } else {
      drawHeight = containerHeight;
      drawWidth = containerHeight * videoAspect;
      xOffset = (containerWidth - drawWidth) / 2;
      yOffset = 0;
    }

    console.log('Before:', canvasElement.width, canvasElement.height, canvasElement.style.cssText);
    canvasElement.width = containerWidth;
    canvasElement.height = containerHeight;
    canvasElement.style.width = `${containerWidth}px`;
    canvasElement.style.height = `${containerHeight}px`;
    console.log('After:', canvasElement.width, canvasElement.height, canvasElement.style.cssText);

    console.groupEnd();
    return { drawWidth, drawHeight, xOffset, yOffset };
  }

  adjustVideoSize(
    videoElement: HTMLVideoElement,
    containerElement: HTMLElement
  ): void {
    console.group('ResizeService: adjustVideoSize');
    if (!this.resizingEnabled) {
      console.log('Resizing skipped');
      console.groupEnd();
      return;
    }
    console.log('Before:', videoElement.style.width, videoElement.style.height);
    videoElement.style.width = `${containerElement.clientWidth}px`;
    videoElement.style.height = `${containerElement.clientHeight}px`;
    console.log('After:', videoElement.style.width, videoElement.style.height);
    console.groupEnd();
  }

  logDimensionsWebcamOff(
    platformId: Object,
    containerElement: HTMLElement | null,
    isLargeViewport: boolean,
    detectionMode: boolean
  ): void {
    if (!this.resizingEnabled) return;
    // Existing logging logic...
  }

  logDimensionsWebcamOn(
    platformId: Object,
    videoElement: HTMLVideoElement | null,
    canvasElement: HTMLCanvasElement | null,
    containerElement: HTMLElement | null,
    isLargeViewport: boolean,
    detectionMode: boolean
  ): void {
    if (!this.resizingEnabled) return;
    // Existing logging logic...
  }

  setResizingEnabled(enabled: boolean): void {
    console.log('ResizeService: setResizingEnabled', enabled);
    this.resizingEnabled = enabled;
  }

  setSetupComplete(complete: boolean): void {
    console.log('ResizeService: setSetupComplete', complete);
    this.setupCompleteSubject.next(complete);
  }
}