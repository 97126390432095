import { Injectable } from '@angular/core';
import { Firestore, collection, getDocs, writeBatch, doc, updateDoc } from '@angular/fire/firestore';
import { AudioDetectionService } from './audio-detection.service';

@Injectable({
  providedIn: 'root'
})
export class SoundProfileService {
  constructor(
    private firestore: Firestore,
    private audioDetectionService: AudioDetectionService
  ) {}

  async loadSoundProfiles(): Promise<{ positiveProfiles: number, negativeProfiles: number }> {
    try {
      console.log('Loading sound profiles from Firestore path: config/soundSettings/soundProfiles');
      const result = await this.audioDetectionService.reloadSoundProfiles();
      console.log('Sound profiles loaded:', result);
      console.log('Firestore query path:', 'config/soundSettings/soundProfiles');
      return result;
    } catch (error) {
      console.error('Error loading sound profiles:', error);
      throw error;
    }
  }

  async loadAndConfigureSoundProfiles(): Promise<void> {
    const result = await this.loadSoundProfiles();
    console.log('Sound profiles loaded:', result);
    
    const currentBlowProfile = this.audioDetectionService.getCurrentBlowProfile();
    console.log('Current blow profile:', currentBlowProfile);
    
    if (!currentBlowProfile) {
      console.warn('No blow profile available');
    }
  }

  async clearSoundProfiles(): Promise<void> {
    const profilesRef = collection(this.firestore, 'config/soundSettings/soundProfiles');
    const querySnapshot = await getDocs(profilesRef);

    const batch = writeBatch(this.firestore); // Changed from this.firestore.batch() to writeBatch(this.firestore)
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log('All sound profiles cleared');
    await this.loadSoundProfiles(); // Reload profiles after clearing
  }

  async updateDeviceType(profileId: string, deviceType: string) {
    const docRef = doc(this.firestore, 'config/soundSettings/soundProfiles', profileId);
    await updateDoc(docRef, { deviceType });
  }
}